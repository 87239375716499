<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-btn icon :x-small="xSmall" v-on="on" @click="clickIcon()">
        <svg
          id="Layer_1"
          :width="xSmall ? 15 : 20"
          :height="xSmall ? 15 : 20"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 118.03 130"
        >
          <polygon
            points="101.96 4.03 97.99 0.06 94.03 4.03 90.25 0.25 86.47 4.03 82.51 0.06 78.54 4.03 74.75 0.24 70.96 4.03 67 0.06 63.03 4.03 59.25 0.25 55.48 4.03 51.51 0.06 48.39 3.18 48.39 3.18 47.55 4.03 43.52 0 39.49 4.03 35.53 0.06 31.56 4.03 27.78 0.25 24.01 4.03 20.04 0.06 16.08 4.03 13.27 1.22 13.27 1.22 13.27 128.78 13.27 128.78 16.08 125.97 20.04 129.94 24.01 125.97 27.78 129.75 31.56 125.97 35.53 129.94 39.49 125.97 43.52 130 47.55 125.97 48.39 126.82 48.39 126.82 51.51 129.94 55.48 125.97 59.25 129.75 63.03 125.97 67 129.94 70.96 125.97 74.75 129.76 78.54 125.97 82.51 129.94 86.47 125.97 90.25 129.75 94.03 125.97 97.99 129.94 101.96 125.97 104.76 128.78 104.76 75.54 104.76 54.46 104.76 1.22 101.96 4.03"
            fill="#6e6f72"
          />
          <path
            d="M58.28,30.9h-3a1.31,1.31,0,0,1,0-2.62h3a1.31,1.31,0,1,1,0,2.62Z"
            transform="translate(-15.98 -10)"
            fill="#fff"
          />
          <path
            d="M84.59,30.9H78a1.31,1.31,0,0,1,0-2.62h6.58a1.31,1.31,0,0,1,0,2.62Zm-13.16,0H64.85a1.31,1.31,0,0,1,0-2.62h6.58a1.31,1.31,0,1,1,0,2.62Z"
            transform="translate(-15.98 -10)"
            fill="#fff"
          />
          <path
            d="M94.11,30.9h-3a1.31,1.31,0,0,1,0-2.62h3a1.31,1.31,0,0,1,0,2.62Z"
            transform="translate(-15.98 -10)"
            fill="#fff"
          />
          <path
            d="M40,43.7H37.07a1.31,1.31,0,0,1,0-2.62H40a1.31,1.31,0,1,1,0,2.62Z"
            transform="translate(-15.98 -10)"
            fill="#fff"
          />
          <path
            d="M103.12,43.7H96.81a1.31,1.31,0,0,1,0-2.62h6.31a1.31,1.31,0,0,1,0,2.62Zm-12.62,0H84.19a1.31,1.31,0,0,1,0-2.62H90.5a1.31,1.31,0,0,1,0,2.62Zm-12.62,0H71.56a1.31,1.31,0,0,1,0-2.62h6.32a1.31,1.31,0,0,1,0,2.62Zm-12.63,0H58.94a1.31,1.31,0,1,1,0-2.62h6.31a1.31,1.31,0,0,1,0,2.62Zm-12.62,0H46.32a1.31,1.31,0,0,1,0-2.62h6.31a1.31,1.31,0,0,1,0,2.62Z"
            transform="translate(-15.98 -10)"
            fill="#fff"
          />
          <path
            d="M112.37,43.7h-2.94a1.31,1.31,0,0,1,0-2.62h2.94a1.31,1.31,0,0,1,0,2.62Z"
            transform="translate(-15.98 -10)"
            fill="#fff"
          />
          <path
            d="M40,54.49H37.07a1.31,1.31,0,1,1,0-2.61H40a1.31,1.31,0,1,1,0,2.61Z"
            transform="translate(-15.98 -10)"
            fill="#fff"
          />
          <path
            d="M103.12,54.49H96.81a1.31,1.31,0,1,1,0-2.61h6.31a1.31,1.31,0,1,1,0,2.61Zm-12.62,0H84.19a1.31,1.31,0,1,1,0-2.61H90.5a1.31,1.31,0,1,1,0,2.61Zm-12.62,0H71.56a1.31,1.31,0,0,1,0-2.61h6.32a1.31,1.31,0,0,1,0,2.61Zm-12.63,0H58.94a1.31,1.31,0,1,1,0-2.61h6.31a1.31,1.31,0,1,1,0,2.61Zm-12.62,0H46.32a1.31,1.31,0,1,1,0-2.61h6.31a1.31,1.31,0,1,1,0,2.61Z"
            transform="translate(-15.98 -10)"
            fill="#fff"
          />
          <path
            d="M112.37,54.49h-2.94a1.31,1.31,0,1,1,0-2.61h2.94a1.31,1.31,0,1,1,0,2.61Z"
            transform="translate(-15.98 -10)"
            fill="#fff"
          />
          <path
            d="M40,64.4H37.07a1.31,1.31,0,1,1,0-2.62H40a1.31,1.31,0,0,1,0,2.62Z"
            transform="translate(-15.98 -10)"
            fill="#fff"
          />
          <path
            d="M65.42,64.4H59.07a1.31,1.31,0,1,1,0-2.62h6.35a1.31,1.31,0,0,1,0,2.62Zm-12.7,0H46.36a1.31,1.31,0,0,1,0-2.62h6.36a1.31,1.31,0,0,1,0,2.62Z"
            transform="translate(-15.98 -10)"
            fill="#fff"
          />
          <path
            d="M74.72,64.4H71.78a1.31,1.31,0,0,1,0-2.62h2.94a1.31,1.31,0,0,1,0,2.62Z"
            transform="translate(-15.98 -10)"
            fill="#fff"
          />
          <path
            d="M97,64.4H94.1a1.31,1.31,0,1,1,0-2.62H97a1.31,1.31,0,0,1,0,2.62Z"
            transform="translate(-15.98 -10)"
            fill="#fff"
          />
          <path
            d="M105.67,64.4h-4.31a1.31,1.31,0,1,1,0-2.62h4.31a1.31,1.31,0,0,1,0,2.62Z"
            transform="translate(-15.98 -10)"
            fill="#fff"
          />
          <path
            d="M112.93,64.4H110a1.31,1.31,0,1,1,0-2.62h2.94a1.31,1.31,0,0,1,0,2.62Z"
            transform="translate(-15.98 -10)"
            fill="#fff"
          />
          <path
            d="M40,73.75H37.07a1.31,1.31,0,1,1,0-2.62H40a1.31,1.31,0,0,1,0,2.62Z"
            transform="translate(-15.98 -10)"
            fill="#fff"
          />
          <path
            d="M65.42,73.75H59.07a1.31,1.31,0,1,1,0-2.62h6.35a1.31,1.31,0,1,1,0,2.62Zm-12.7,0H46.36a1.31,1.31,0,0,1,0-2.62h6.36a1.31,1.31,0,0,1,0,2.62Z"
            transform="translate(-15.98 -10)"
            fill="#fff"
          />
          <path
            d="M74.72,73.75H71.78a1.31,1.31,0,0,1,0-2.62h2.94a1.31,1.31,0,0,1,0,2.62Z"
            transform="translate(-15.98 -10)"
            fill="#fff"
          />
          <path
            d="M97,73.75H94.1a1.31,1.31,0,1,1,0-2.62H97a1.31,1.31,0,0,1,0,2.62Z"
            transform="translate(-15.98 -10)"
            fill="#fff"
          />
          <path
            d="M105.67,73.75h-4.31a1.31,1.31,0,0,1,0-2.62h4.31a1.31,1.31,0,1,1,0,2.62Z"
            transform="translate(-15.98 -10)"
            fill="#fff"
          />
          <path
            d="M112.93,73.75H110a1.31,1.31,0,1,1,0-2.62h2.94a1.31,1.31,0,0,1,0,2.62Z"
            transform="translate(-15.98 -10)"
            fill="#fff"
          />
          <path
            d="M97,86.32H94.1a1.31,1.31,0,1,1,0-2.61H97a1.31,1.31,0,1,1,0,2.61Z"
            transform="translate(-15.98 -10)"
            fill="#fff"
          />
          <path
            d="M105.67,86.32h-4.31a1.31,1.31,0,1,1,0-2.61h4.31a1.31,1.31,0,1,1,0,2.61Z"
            transform="translate(-15.98 -10)"
            fill="#fff"
          />
          <path
            d="M112.93,86.32H110a1.31,1.31,0,1,1,0-2.61h2.94a1.31,1.31,0,1,1,0,2.61Z"
            transform="translate(-15.98 -10)"
            fill="#fff"
          />
          <path
            d="M40.11,86.32H37.17a1.31,1.31,0,1,1,0-2.61h2.94a1.31,1.31,0,1,1,0,2.61Z"
            transform="translate(-15.98 -10)"
            fill="#fff"
          />
          <path
            d="M58.53,86.32h-4.6a1.31,1.31,0,1,1,0-2.61h4.6a1.31,1.31,0,1,1,0,2.61Zm-9.21,0h-4.6a1.31,1.31,0,1,1,0-2.61h4.6a1.31,1.31,0,1,1,0,2.61Z"
            transform="translate(-15.98 -10)"
            fill="#fff"
          />
          <path
            d="M66.08,86.32H63.13a1.31,1.31,0,0,1,0-2.61h2.95a1.31,1.31,0,0,1,0,2.61Z"
            transform="translate(-15.98 -10)"
            fill="#fff"
          />
          <path
            d="M74.93,134A59,59,0,1,1,134,75.25C134,107.49,107.33,134,74.93,134Zm27.32-95.85a45.85,45.85,0,0,0-64.13,64.11Zm9.68,9.75L47.84,112a45.84,45.84,0,0,0,64.09-64.08Z"
            transform="translate(-15.98 -10)"
            fill="#ed1c24"
          />
        </svg>
      </v-btn>
    </template>
    <span>Cancel Ticket</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    xSmall: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clickIcon() {
      this.$emit("click");
    }
  }
};
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>