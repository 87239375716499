<template>
  <v-app>
    <img
      width="100%"
      height="100%"
      style="position:fixed;"
      :src="require('./assets/white_bg.png')"
    />
    <user-toolbar v-if="$store.state.token"></user-toolbar>
    <v-main @scroll="handleScroll">
      <router-view></router-view>
    </v-main>
    <page-loading v-if="$store.state.loading" />
    <page-unauthorized v-if="$store.state.unauthorized" />
    <info-bar></info-bar>
    <v-fab-transition>
      <v-btn
        v-if="
          offsetTop === 0 &&
            $store.state.showChevronButtons &&
            $store.state.hasItems
        "
        ref="button"
        fixed
        dark
        right
        bottom
        fab
        small
        color="primary"
        @click="$vuetify.goTo(pageHeight)"
      >
        <v-icon large>mdi-chevron-down</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-fab-transition>
      <v-btn
        ref="button"
        fixed
        v-if="
          offsetTop > 100 &&
            $store.state.showChevronButtons &&
            $store.state.hasItems
        "
        dark
        right
        bottom
        fab
        small
        color="primary"
        @click="$vuetify.goTo(0)"
      >
        <v-icon large>mdi-chevron-up</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-footer
      v-if="$store.state.token === null"
      padless
      style="position:absolute; right: 1px; bottom: 5px;"
    >
      <v-card flat tile>
        <v-card-text class="py-2">
          <strong>{{ `${version}` }}</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import UserToolbar from "@/components/UserToolbar";
import PageLoading from "@/components/PageLoading";
import PageUnauthorized from "@/components/PageUnauthorized";

export default {
  name: "App",
  components: {
    UserToolbar,
    PageLoading,
    PageUnauthorized,
  },
  data: () => ({
    offsetTop: 0,
    version: `${process.env.VUE_APP_VERSION}`,
  }),
  watch: {
    async $route(to, from) {
      this.$store.dispatch("dispatchSnailtrail", []);
      await this.pageAaccess();
      if (
        this.$route.name === "Safety Alerts" ||
        this.$route.name === "Security Alerts" ||
        this.$route.name === "Dispatch Report" ||
        this.$route.name === "Vehicle Usage"
      ) {
        console.log(to + " " + from);
      } else {
        this.$store.dispatch("dispatchReportTypeText", "");
        this.$store.dispatch("dispatchReportVehiclesArray", []);
      }

      if (
        this.$route.name === "Safety Alerts" ||
        this.$route.name === "Security Alerts" ||
        this.$route.name === "Dispatch Report" ||
        this.$route.name === "Vehicle Usage" ||
        this.$route.name === "Outlet Dispatch"
      ) {
        this.$store.dispatch("dispatchShowChevronButtons", true);
      } else {
        this.$store.dispatch("dispatchShowChevronButtons", false);
        this.$store.dispatch("dispatchHasItems", false);
      }
    },
  },
  created: function() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed: function() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    pageHeight() {
      return document.body.scrollHeight;
    },
  },
  methods: {
    handleScroll() {
      this.offsetTop = window.pageYOffset || document.documentElement.scrollTop;
    },
    async pageAaccess() {
      let pageAccess = false;

      let data = [];

      if (
        this.$route.name === "Page Not Found" ||
        this.$route.name === "Login" ||
        this.$route.name === "Registration" ||
        this.$route.name === "Index"
      ) {
        this.$store.dispatch("clearStore");
        pageAccess = true;
      } else {
        if (this.$store.state.menu.length) {
          pageAccess = this.$store.state.menu.some((item) => {
            if (
              this.$route.name === "Dashboard Map View" ||
              this.$route.name === "Safety Alerts" ||
              this.$route.name === "Security Alerts" ||
              this.$route.name === "Dispatch Report" ||
              this.$route.name === "Vehicle Usage" ||
              this.$route.name === "Notifications" ||
              this.$route.name === "Active Monitoring"
            ) {
              return true;
            } else {
              data = item.modules;
              return item.link === this.$route.path;
            }
          });
        }
      }

      if (pageAccess) {
        this.$store.dispatch("dispatchUnauthorized", false);
        this.$store.dispatch("dispatchModule", data);
      } else {
        this.$store.dispatch("dispatchUnauthorized", true);
      }
    },
  },
};
</script>
