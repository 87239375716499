<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    :aria-labelledby="iconName"
    role="presentation"
  >
    <title :id="iconName" lang="en">{{ iconName }}</title>
    <g :fill="iconColor">
      <path
        v-if="icon === 'home'"
        d="M1,10h6c0.55,0,1-0.45,1-1V1c0-0.55-0.45-1-1-1H1C0.45,0,0,0.45,0,1v8C0,9.55,0.45,10,1,10z M1,18h6c0.55,0,1-0.45,1-1v-4c0-0.55-0.45-1-1-1H1c-0.55,0-1,0.45-1,1v4C0,17.55,0.45,18,1,18z M11,18h6c0.55,0,1-0.45,1-1V9c0-0.55-0.45-1-1-1h-6c-0.55,0-1,0.45-1,1v8C10,17.55,10.45,18,11,18z M10,1v4c0,0.55,0.45,1,1,1h6c0.55,0,1-0.45,1-1V1c0-0.55-0.45-1-1-1h-6C10.45,0,10,0.45,10,1z"
      />

      <path
        v-else-if="icon === 'pages'"
        d="M19,2L14,6.5V17.5L19,13V2M6.5,5C4.55,5 2.45,5.4 1,6.5V21.16C1,21.41 1.25,21.66 1.5,21.66C1.6,21.66 1.65,21.59 1.75,21.59C3.1,20.94 5.05,20.5 6.5,20.5C8.45,20.5 10.55,20.9 12,22C13.35,21.15 15.8,20.5 17.5,20.5C19.15,20.5 20.85,20.81 22.25,21.56C22.35,21.61 22.4,21.59 22.5,21.59C22.75,21.59 23,21.34 23,21.09V6.5C22.4,6.05 21.75,5.75 21,5.5V7.5L21,13V19C19.9,18.65 18.7,18.5 17.5,18.5C15.8,18.5 13.35,19.15 12,20V13L12,8.5V6.5C10.55,5.4 8.45,5 6.5,5V5Z"
      />
      <path
        v-else-if="
          icon === 'roles' ||
            icon === 'company roles' ||
            icon === 'sub-company roles'
        "
        d="M10,2H14A2,2 0 0,1 16,4V6H20A2,2 0 0,1 22,8V19A2,2 0 0,1 20,21H4C2.89,21 2,20.1 2,19V8C2,6.89 2.89,6 4,6H8V4C8,2.89 8.89,2 10,2M14,6V4H10V6H14Z"
      />
      <path
        v-else-if="icon === 'employees'"
        d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7.753 18.305c-.261-.586-.789-.991-1.871-1.241-2.293-.529-4.428-.993-3.393-2.945 3.145-5.942.833-9.119-2.489-9.119-3.388 0-5.644 3.299-2.489 9.119 1.066 1.964-1.148 2.427-3.393 2.945-1.084.25-1.608.658-1.867 1.246-1.405-1.723-2.251-3.919-2.251-6.31 0-5.514 4.486-10 10-10s10 4.486 10 10c0 2.389-.845 4.583-2.247 6.305z"
      />
      <path
        v-else-if="icon === 'employee'"
        d="M12,0C5.373,0,0,5.373,0,12c0,6.627,5.373,12,12,12s12-5.373,12-12C24,5.373,18.627,0,12,0z M19.753,18.306 c-0.261-0.587-0.789-0.991-1.871-1.241c-2.293-0.529-4.428-0.994-3.393-2.945C17.634,8.177,15.322,5,12,5 c-3.388,0-5.644,3.299-2.489,9.119c1.066,1.963-1.148,2.427-3.393,2.945c-1.084,0.25-1.608,0.657-1.867,1.245 C2.846,16.587,2,14.391,2,12C2,6.486,6.486,2,12,2s10,4.486,10,10C22,14.39,21.155,16.584,19.753,18.306z"
      />
      <path
        v-else-if="icon === 'companies' || icon === 'sub-companies'"
        d="M18,15H16V17H18M18,11H16V13H18M20,19H12V17H14V15H12V13H14V11H12V9H20M10,7H8V5H10M10,11H8V9H10M10,15H8V13H10M10,19H8V17H10M6,7H4V5H6M6,11H4V9H6M6,15H4V13H6M6,19H4V17H6M12,7V3H2V21H22V7H12Z"
      />
      <path
        v-else-if="icon === 'pre-approve clients'"
        d="M11 8C11 10.21 9.21 12 7 12C4.79 12 3 10.21 3 8C3 5.79 4.79 4 7 4C9.21 4 11 5.79 11 8M11 14.72V20H0V18C0 15.79 3.13 14 7 14C8.5 14 9.87 14.27 11 14.72M24 20H13V3H24V20M16 11.5C16 10.12 17.12 9 18.5 9C19.88 9 21 10.12 21 11.5C21 12.88 19.88 14 18.5 14C17.12 14 16 12.88 16 11.5M22 7C20.9 7 20 6.11 20 5H17C17 6.11 16.11 7 15 7V16C16.11 16 17 16.9 17 18H20C20 16.9 20.9 16 22 16V7Z"
      />
      <path
        v-else-if="icon === 'vehicles'"
        d="M18,18.5A1.5,1.5 0 0,1 16.5,17A1.5,1.5 0 0,1 18,15.5A1.5,1.5 0 0,1 19.5,17A1.5,1.5 0 0,1 18,18.5M19.5,9.5L21.46,12H17V9.5M6,18.5A1.5,1.5 0 0,1 4.5,17A1.5,1.5 0 0,1 6,15.5A1.5,1.5 0 0,1 7.5,17A1.5,1.5 0 0,1 6,18.5M20,8H17V4H3C1.89,4 1,4.89 1,6V17H3A3,3 0 0,0 6,20A3,3 0 0,0 9,17H15A3,3 0 0,0 18,20A3,3 0 0,0 21,17H23V12L20,8Z"
      />
      <path
        v-else-if="icon === 'gps device types'"
        d="M2,10H0V8h2V10z M2,3c0-0.551,0.448-1,1-1h4V0H3C1.343,0,0,1.343,0,3v4h2V3z M10,0H8v2h2V0z M2,11H0v2h2V11z M2,14H0v2h2V14z M13,0h-2v2h2V0z M9.078,13h3.078c0.552,0,1-0.448,1-1s-0.448-1-1-1H9.078c-0.552,0-1,0.448-1,1S8.526,13,9.078,13z M4,4h16v16H4V4z M6.891,12c0,1.104,0.896,2,2,2H15c1.104,0,2-0.896,2-2s-0.896-2-2-2H8.891C7.786,10,6.891,10.896,6.891,12z M21,0 h-4v2h4c0.552,0,1,0.449,1,1v4h2V3C24,1.343,22.657,0,21,0z M22,16h2v-2h-2V16z M22,10h2V8h-2V10z M22,13h2v-2h-2V13z M16,0h-2v2h2 V0z M11,24h2v-2h-2V24z M2,21v-4H0v4c0,1.656,1.343,3,3,3h4v-2H3C2.448,22,2,21.552,2,21z M8,24h2v-2H8V24z M14,24h2v-2h-2V24z M22,21c0,0.552-0.448,1-1,1h-4v2h4c1.657,0,3-1.344,3-3v-4h-2V21z"
      />
      <path
        v-else-if="icon === 'gps device'"
        d="M21,4c0-1.104-0.896-2-2-2H4C2.896,2,2,2.896,2,4v15c0,1.104,0.896,2,2,2h15c1.104,0,2-0.896,2-2V4z M19,18.25 c0,0.414-0.336,0.75-0.75,0.75H4.75C4.336,19,4,18.664,4,18.25V4.75C4,4.336,4.336,4,4.75,4h13.5C18.664,4,19,4.336,19,4.75V18.25z M10,22v1H9v-1H10z M12,22v1h-1v-1H12z M8,22v1H7v-1H8z M14,22v1h-1v-1H14z M16,22v1h-1v-1H16z M10,0v1H9V0H10z M12,0v1h-1V0H12z M8,0v1H7V0H8z M14,0v1h-1V0H14z M16,0v1h-1V0H16z M22,13h1v1h-1V13z M22,9h1v1h-1V9z M22,7h1v1h-1V7z M22,11h1v1h-1V11z M22,15h1v1 h-1V15z M0,13h1v1H0V13z M0,9h1v1H0V9z M0,7h1v1H0V7z M0,11h1v1H0V11z M0,15h1v1H0V15z M11,11H6V6h5V11z M17,11h-5V6h5V11z M11,17H6 v-5h5V17z M17,17h-5v-5h5V17z"
      />
      <path
        v-else-if="icon === 'outlets'"
        d="M12 0c-3.313 0-6 2.687-6 6 0 2.972 2.164 5.433 5 5.91v8.09h2v-8.089c2.836-.477 5-2.938 5-5.91 0-3.314-2.687-6.001-6-6.001zm-.707 4.508c-.549.65-1.423.8-1.953.333s-.516-1.372.034-2.022c.548-.65 1.422-.799 1.952-.333.53.467.515 1.372-.033 2.022zm8.707 11.492h-5v2h3.764l.5 1h-4.264v1.175l.783 1.825h-7.488l.705-1.643v-1.357h-2.042l-1.011-1h3.053v-2h-5l-4 8h24l-4-8zm-12.794 6h-3.97l1.764-3.528 1.516 1.528h1.549l-.859 2zm8.808-2h3.75l1 2h-3.892l-.858-2z"
      />
      <path
        v-else-if="icon === 'outlet dispatch' || icon === 'outlet monitoring'"
        d="M22.114,1.444H2.009C0.901,1.444,0,2.346,0,3.454v13.103c0,1.109,0.901,2.01,2.009,2.01h6.478l-1.229,2.711 c-0.127,0.281-0.103,0.605,0.063,0.863c0.167,0.26,0.453,0.416,0.76,0.416h7.96h0.001c0.5,0,0.905-0.406,0.905-0.906 c0-0.149-0.037-0.291-0.102-0.416l-1.211-2.668h6.479c1.107,0,2.01-0.899,2.01-2.01V3.454 C24.124,2.346,23.223,1.444,22.114,1.444z M22.314,16.557c0,0.111-0.09,0.201-0.2,0.201h-7.882H9.891H2.009 c-0.11,0-0.2-0.09-0.2-0.201V3.454c0-0.11,0.09-0.2,0.2-0.2h20.105c0.11,0,0.2,0.09,0.2,0.2V16.557L22.314,16.557z M4.393,9.754C4.962,9.818,5.5,9.612,5.878,9.237l4.94,1.775c0.051,0.869,0.721,1.603,1.615,1.704 c0.999,0.111,1.903-0.609,2.016-1.608c0.007-0.071,0.012-0.141,0.011-0.21l4.17-3.042c0.206,0.118,0.438,0.196,0.69,0.225 c1,0.113,1.904-0.608,2.017-1.607s-0.608-1.903-1.606-2.016c-0.998-0.112-1.902,0.608-2.016,1.607 c-0.035,0.32,0.014,0.629,0.131,0.907l-3.803,2.773c-0.29-0.352-0.713-0.597-1.201-0.653c-0.693-0.078-1.339,0.245-1.708,0.783 l-4.73-1.699c0.001-0.011,0.003-0.021,0.004-0.03C6.521,7.148,5.8,6.243,4.802,6.131c-1-0.113-1.903,0.608-2.016,1.607 C2.673,8.737,3.395,9.641,4.393,9.754z M18.885,6.198c0.04-0.354,0.36-0.61,0.715-0.57c0.355,0.04,0.61,0.36,0.57,0.715 c-0.04,0.355-0.359,0.61-0.715,0.57S18.846,6.552,18.885,6.198z M11.996,10.833c0.039-0.354,0.36-0.61,0.714-0.57 c0.354,0.039,0.61,0.36,0.571,0.715c-0.04,0.355-0.361,0.61-0.715,0.57C12.212,11.507,11.955,11.187,11.996,10.833z M3.955,7.87 c0.04-0.355,0.36-0.61,0.715-0.57s0.61,0.361,0.57,0.715c-0.04,0.354-0.36,0.61-0.715,0.57S3.915,8.225,3.955,7.87z M19.527,9.073c-0.09,0-0.182-0.006-0.271-0.016c-0.192-0.022-0.379-0.065-0.559-0.131l-3.669,2.677 c-0.153,1.204-1.176,2.104-2.391,2.104c-0.09,0-0.181-0.004-0.271-0.014c-1.033-0.117-1.854-0.871-2.08-1.854l-4.292-1.542 c-0.407,0.291-0.891,0.449-1.398,0.449l0,0c-0.09,0-0.181-0.006-0.271-0.016c-0.627-0.07-1.17-0.376-1.553-0.82v5.934H21.35 V8.241C20.904,8.753,20.251,9.073,19.527,9.073z"
      />
      <path
        v-else-if="icon === 'alert zone'"
        d="M12 2C15.9 2 19 5.1 19 9C19 14.2 12 22 12 22S5 14.2 5 9C5 5.1 8.1 2 12 2M11 6V12H13V6H11M11 14V16H13V14H11Z"
      />
      <path
        v-else
        d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M7,20H9V14H7V20M11,20H13V12H11V20M15,20H17V16H15V20Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: "home",
    },
    iconName: {
      type: String,
      default: "box",
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 22,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
