<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card tile>
      <v-toolbar :dark="dark" :color="color">
        <span class="headline font-weight-bold">{{ title }}</span>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn v-if="saveButton" icon dark @click="saveDialog">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container v-if="dialogLoading" style="text-align: center;">
        <v-row>
          <v-col cols="12">
            <v-progress-circular :size="50" :color="dialogLoadingColor" indeterminate></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
      <slot v-else></slot>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: "primary"
    },
    dark: {
      type: Boolean,
      default: false
    },
    saveButton: {
      type: Boolean,
      default: false
    },
    dialogLoading: {
      type: Boolean,
      default: false
    },
    dialogLoadingColor: {
      type: String,
      default: "primary"
    }
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    saveDialog() {
      this.$emit("saveDialog");
    }
  }
};
</script>

<style>
</style>