import Authentication from "@/services/api_config/AuthenticationApi";
import Rest from "@/services/api_config/RestApi";

export default {
  Login(data) {
    return Authentication().post("auth/wayko-login", data);
  },
  Registration(data) {
    return Authentication().post("auth/wayko-registration", data);
  },
  getCompanies(params) {
    var data = ``;
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    if (
      params.approved !== null &&
      params.approved !== undefined &&
      params.approved !== ""
    ) {
      data += `&approved=${params.approved}`;
    }
    return Rest().get("wayko/company?" + data.substring(1));
  },
  addCompany(data) {
    return Rest().put("wayko/company/add", data);
  },
  editCompany(id, data) {
    return Rest().patch("wayko/company/" + id, data);
  },
  deleteCompany(id) {
    return Rest().delete("wayko/company/" + id);
  },
  subscribe(id, data) {
    return Rest().patch("wayko/company/subscribe/" + id, data);
  },
  suspendCompany(id, data) {
    return Rest().patch("wayko/company/suspend/" + id, data);
  },
  getCompanyMenu(data) {
    return Rest().get(
      "wayko/com/page/get-company-menu?company_id=" + data.company_id
    );
  },
  operationsApproval(data) {
    return Rest().patch("wayko/client/client-approval/" + data);
  },
  getPages(params) {
    var data = ``;
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    return Rest().get("wayko/page?" + data.substring(1));
  },
  getPage(id) {
    return Rest().get("wayko/page/" + id);
  },
  addPage(data) {
    return Rest().put("wayko/page/add", data);
  },
  editPage(id, data) {
    return Rest().patch("wayko/page/" + id, data);
  },
  deletePage(id) {
    return Rest().delete("wayko/page/" + id);
  },
  getGPSDevices(params) {
    var data = ``;
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    if (
      params.company_id !== null &&
      params.company_id !== undefined &&
      params.company_id !== ""
    ) {
      data += `&company_id=${params.company_id}`;
    }
    return Rest().get("wayko/gps-devices?" + data.substring(1));
  },
  addGPSDevice(data) {
    return Rest().put("wayko/gps-device/add", data);
  },
  editGPSDevice(id, data) {
    return Rest().patch("wayko/gps-device/" + id, data);
  },
  deleteGPSDevice(id) {
    return Rest().delete("wayko/gps-device/" + id);
  },
  unassignCompany(id) {
    return Rest().patch("wayko/gps-device/unassign-company/" + id);
  },
  unassignSubCompany(id) {
    return Rest().patch("wayko/gps-device/unassign-sub-company/" + id);
  },
  unassignVehicle(id) {
    return Rest().patch("wayko/gps-device/unassign-vehicle/" + id);
  },
  getGPSDeviceTypes(params) {
    var data = ``;
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    return Rest().get("wayko/gps-device-types?" + data.substring(1));
  },
  addGPSDeviceType(data) {
    return Rest().put("wayko/gps-device-type/add", data);
  },
  editGPSDeviceType(id, data) {
    return Rest().patch("wayko/gps-device-type/" + id, data);
  },
  deleteGPSDeviceType(id) {
    return Rest().delete("wayko/gps-device-type/" + id);
  },
  getRoles(params) {
    var data = ``;
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    return Rest().get("wayko/role?" + data.substring(1));
  },
  getRole(id) {
    return Rest().get("wayko/role/" + id);
  },
  addRole(data) {
    return Rest().put("wayko/role/add", data);
  },
  editRole(data, id) {
    return Rest().patch("wayko/role/" + id, data);
  },
  deleteRole(id) {
    return Rest().delete("wayko/role/" + id);
  },
  getModules(params) {
    let data = ``;
    if (
      params.page_id !== null &&
      params.page_id !== undefined &&
      params.page_id !== ""
    ) {
      data += `&page_id=${params.page_id}`;
    }

    return Rest().get("wayko/module?" + data.substring(1));
  },
  getModule(id) {
    return Rest().get("wayko/module/" + id);
  },
  addModule(data) {
    return Rest().put("wayko/module/add", data);
  },
  editModule(id, data) {
    return Rest().patch("wayko/module/" + id, data);
  },
  deleteModule(id) {
    return Rest().delete("wayko/module/" + id);
  },
  getEmployees(params) {
    let data = ``;
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    return Rest().get("wayko/employee?" + data.substring(1));
  },
  getEmployee(id) {
    return Rest().get("wayko/employee/" + id);
  },
  addEmployee(data) {
    return Rest().put("wayko/employee/add", data);
  },
  editEmployee(id, data) {
    return Rest().patch("wayko/employee/" + id, data);
  },
  deleteEmployee(id) {
    return Rest().delete("wayko/employee/" + id);
  },
  resetPassword(id) {
    return Rest().patch("wayko/employee/reset-password/" + id);
  },
  getCompanyRoles(params) {
    var data = ``;
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    return Rest().get("wayko/company-role?" + data.substring(1));
  },
  getCompanyRole(id) {
    return Rest().get("wayko/company-role/" + id);
  },
  addCompanyRole(data) {
    return Rest().put("wayko/company-role/add", data);
  },
  editCompanyRole(data, id) {
    return Rest().patch("wayko/company-role/" + id, data);
  },
  deleteCompanyRole(id) {
    return Rest().delete("wayko/company-role/" + id);
  },
  getCompanyPages(params) {
    let data = ``;
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    return Rest().get("wayko/com/page?" + data.substring(1));
  },
  getCompanyPage(id) {
    return Rest().get("wayko/com/page/" + id);
  },
  addCompanyPage(data) {
    return Rest().put("wayko/com/page/add", data);
  },
  editCompanyPage(id, data) {
    return Rest().patch("wayko/com/page/" + id, data);
  },
  deleteCompanyPage(id) {
    return Rest().delete("wayko/com/page/" + id);
  },
  getCompanyModules(params) {
    let data = ``;
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }
    if (
      params.page_id !== null &&
      params.page_id !== undefined &&
      params.page_id !== ""
    ) {
      data += `&page_id=${params.page_id}`;
    }

    return Rest().get("wayko/com/module?" + data.substring(1));
  },
  addCompanyModule(data) {
    return Rest().put("wayko/com/module/add", data);
  },
  editCompanyModule(data, id) {
    return Rest().patch("wayko/com/module/" + id, data);
  },
  deleteCompanyModule(id) {
    return Rest().delete("wayko/com/module/" + id);
  },
  getSubCompanyRoles(params) {
    var data = ``;
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    return Rest().get("wayko/sub-company-role?" + data.substring(1));
  },
  getSubCompanyRole(id) {
    return Rest().get("wayko/sub-company-role/" + id);
  },
  addSubCompanyRole(data) {
    return Rest().put("wayko/sub-company-role/add", data);
  },
  editSubCompanyRole(data, id) {
    return Rest().patch("wayko/sub-company-role/" + id, data);
  },
  deleteSubCompanyRole(id) {
    return Rest().delete("wayko/sub-company-role/" + id);
  },
  getSubCompanyPages(params) {
    let data = ``;
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    return Rest().get("wayko/com/sub/page?" + data.substring(1));
  },
  getSubCompanyPage(id) {
    return Rest().get("wayko/com/sub/page/" + id);
  },
  addSubCompanyPage(data) {
    return Rest().put("wayko/com/sub/page/add", data);
  },
  editSubCompanyPage(id, data) {
    return Rest().patch("wayko/com/sub/page/" + id, data);
  },
  deleteSubCompanyPage(id) {
    return Rest().delete("wayko/com/sub/page/" + id);
  },
  getSubCompanyModules(params) {
    let data = ``;
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }
    if (
      params.page_id !== null &&
      params.page_id !== undefined &&
      params.page_id !== ""
    ) {
      data += `&page_id=${params.page_id}`;
    }

    return Rest().get("wayko/com/sub/module?" + data.substring(1));
  },
  addSubCompanyModule(data) {
    return Rest().put("wayko/com/sub/module/add", data);
  },
  editSubCompanyModule(data, id) {
    return Rest().patch("wayko/com/sub/module/" + id, data);
  },
  deleteSubCompanyModule(id) {
    return Rest().delete("wayko/com/sub/module/" + id);
  },
  changePassword(data, id) {
    return Rest().patch("/change-password/" + id, data);
  },
  userSettings(data, id) {
    return Rest().patch("/user-settings/" + id, data);
  },
  userStatus() {
    return Authentication().post("wayko/user-status");
  },
  getDashboard(params) {
    let data = ``;
    if (
      params.company_id !== null &&
      params.company_id !== undefined &&
      params.company_id !== ""
    ) {
      data += `&company_id=${params.company_id}`;
    }

    return Rest().get("wayko/tracking-dashboard?" + data.substring(1));
  },
  getDashboardDetails(params) {
    let data = ``;
    if (
      params.company_id !== null &&
      params.company_id !== undefined &&
      params.company_id !== ""
    ) {
      data += `&company_id=${params.company_id}`;
    }

    return Rest().get("wayko/tracking-dashboard-details?" + data.substring(1));
  },
  getDashboardRunningVehicles(params) {
    let data = ``;
    if (
      params.company_id !== null &&
      params.company_id !== undefined &&
      params.company_id !== ""
    ) {
      data += `&company_id=${params.company_id}`;
    }

    return Rest().get(
      "wayko/tracking-dashboard-running-vehicles?" + data.substring(1)
    );
  },
  getCurrentAlerts(params) {
    let data = ``;
    if (
      params.imei_no !== null &&
      params.imei_no !== undefined &&
      params.imei_no !== ""
    ) {
      data += `&imei_no=${params.imei_no}`;
    }
    if (
      params.company_id !== null &&
      params.company_id !== undefined &&
      params.company_id !== ""
    ) {
      data += `&company_id=${params.company_id}`;
    }
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }

    return Rest().get("wayko/com/current-alerts?" + data.substring(1));
  },
  disconnectSocketConnection() {
    return Rest().post("wayko/tracking-dashboard-disconnect-socket");
  },
};
