import moment from "moment";

export default {
  methods: {
    $infoBar(data) {
      this.$store.dispatch("dispatchInfobar", {
        text: data.text,
        type: data.type,
      });
      this.$store.dispatch("dispatchInfobarStatus", true);
    },
    $capitalizeName(name) {
      if (!name) return;

      return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
    },
    $isEmpty(data) {
      if (data === undefined || data === "") {
        return;
      }
      return Object.keys(data).length === 0;
    },
    $moduleAccess(data) {
      const access = this.$store.state.module.some((item) => {
        if (item.name === data) {
          return true;
        }
      });

      if (!access) {
        return false;
      }

      return true;
    },
    $dateFormat: function(data, format) {
      let now = null;
      if (data) {
        now = moment(data).format(format);
      } else {
        now = moment().format(format);
      }

      return now;
    },
    $addSevenCurrentDate: function(date) {
      return moment(date)
        .add(6, "d")
        .format("YYYY-MM-DD");
    },
    $dateValidator: function(date_to) {
      const currentDateTime = moment().format("YYYY-MM-DD");

      const dateChecker = moment(date_to).isAfter(currentDateTime);

      if (!dateChecker) {
        return date_to;
      } else {
        return currentDateTime;
      }
    },
    $vehicleType: function(type) {
      let svg = ``;

      switch (type) {
        case "Bus":
          svg = `M7.654,0.02c0,0,4.557,0.026,5.797,0.666c1.238,0.64,1.889,2.923,1.889,2.923v30.499v0.008
	c0,0-0.45,1.107-1.731,1.51c-0.912,0.284-3.851,0.339-5.937,0.339c-2.086,0-5.022-0.055-5.936-0.339C0.453,35.223,0,34.113,0,34.113
	v-0.006V3.609c0,0,0.653-2.284,1.891-2.923C3.13,0.046,7.686,0.02,7.686,0.02 M3.939,0.699c0,0-2.793,0.118-2.925,2.023
	C0.885,4.627,3.833,2.461,3.939,0.699z M14.324,2.72c-0.132-1.905-2.925-2.023-2.925-2.023C11.503,2.459,14.453,4.625,14.324,2.72z
	 M7.639,10.073c2.957,0,5.353,0.349,5.353,0.349s1.43-2.579,1.43-4.193S7.639,4.732,7.639,4.732s-6.78-0.116-6.78,1.498
	s1.408,4.193,1.408,4.193S4.682,10.073,7.639,10.073z M0.833,33.241c0,0.396,0.321,0.718,0.717,0.718s0.718-0.32,0.718-0.718V15.748
	v-3.619c0-0.516-0.881-1.556-1.079-1.556c-0.2,0-0.359,0.161-0.359,0.36l0.004,4.814L0.833,34.241V33.241z M14.478,34.241
	l-0.001-18.494l0.004-4.814c0-0.199-0.159-0.36-0.358-0.36s-1.08,1.04-1.08,1.556v3.619v17.493c0,0.397,0.322,0.718,0.719,0.718
	s0.717-0.322,0.717-0.718V34.241z`;
          break;
        case "Truck/Close Van":
          svg = `M7.654,0.02c0,0,4.557,0.026,5.797,0.666c1.238,0.64,1.889,2.923,1.889,2.923v12.499v0.008
	c0,0-0.45,1.107-1.731,1.51c0.252,0,0.468,0,0.651,0c1.08,0,1.08,1.08,1.08,1.08s0,12.161,0,16.215c0,1.08-1.08,1.08-1.08,1.08H1.08
	c0,0-1.08,0-1.08-1.08c0-4.054,0-16.215,0-16.215s0-1.08,1.08-1.08c0.083,0,0.559,0,0.655,0C0.453,17.223,0,16.113,0,16.113v-0.006
	V3.609c0,0,0.653-2.284,1.891-2.923C3.13,0.046,7.686,0.02,7.686,0.02 M7.668,9.875c2.847,0,5.153,0.335,5.153,0.335
	s1.377-2.483,1.377-4.037c0-1.553-6.53-1.441-6.53-1.441S1.14,4.621,1.14,6.174c0,1.554,1.356,4.037,1.356,4.037
	S4.821,9.875,7.668,9.875z M1.145,15.282C1.145,15.678,1.466,16,1.862,16s0.718-0.32,0.718-0.718v-0.493V12.17
	c0-0.516-0.881-1.556-1.079-1.556c-0.2,0-0.359,0.161-0.359,0.36l0.004,3.814 M8199.17,18.255 M-8183.831,18.255 M4.066,1.027
	c0,0-2.793,0.118-2.924,2.023C1.013,4.955,3.96,2.789,4.066,1.027z M14.197,3.048c-0.133-1.905-2.926-2.023-2.926-2.023
	C11.376,2.787,14.326,4.953,14.197,3.048z M14.193,14.788l0.004-3.814c0-0.199-0.159-0.36-0.359-0.36
	c-0.197,0-1.078,1.04-1.078,1.556v2.619v0.493c0,0.397,0.322,0.718,0.718,0.718s0.717-0.322,0.717-0.718`;
          break;
        case "Sedan":
          svg = `M7.654,0.02c0,0,4.557,0.026,5.797,0.666c1.238,0.64,1.889,2.923,1.889,2.923v30.499v0.007
	c0,0-0.451,1.108-1.732,1.51c-0.912,0.284-3.85,0.339-5.937,0.339c-2.086,0-5.022-0.055-5.936-0.339C0.453,35.223,0,34.113,0,34.113
	v-0.006V3.609c0,0,0.653-2.284,1.891-2.923C3.13,0.046,7.686,0.02,7.686,0.02 M4.069,1.037c0,0-2.793,0.118-2.925,2.023
	C1.015,4.965,3.963,2.799,4.069,1.037z M14.195,3.058c-0.132-1.905-2.925-2.023-2.925-2.023C11.375,2.797,14.324,4.963,14.195,3.058
	z M7.66,13.22c2.857,0,5.171,0.337,5.171,0.337s1.382-2.492,1.382-4.051c0-1.56-6.553-1.446-6.553-1.446S1.109,7.948,1.109,9.507
	c0,1.559,1.361,4.051,1.361,4.051S4.803,13.22,7.66,13.22z M12.662,19.361c0,0.424,0.344,0.767,0.767,0.767s0.765-0.343,0.765-0.767
	l0.006-5.13c0-0.212-0.172-0.384-0.385-0.384s-1.152,1.108-1.152,1.658V19.361L12.662,19.361z M12.662,23.852
	c0,0.549,0.938,1.657,1.152,1.657c0.211,0,0.383-0.173,0.383-0.383l-0.004-3.796c0-0.424-0.343-0.768-0.766-0.768
	s-0.768,0.344-0.768,0.768v2.521H12.662z M2.504,26.042c0,0-1.354,1.51-1.354,3.217s6.521,1.582,6.521,1.582
	s6.521,0.125,6.521-1.582s-1.376-3.217-1.376-3.217S10.513,26.41,7.67,26.41C4.826,26.41,2.504,26.042,2.504,26.042z M2.675,15.505
	c0-0.55-0.939-1.658-1.151-1.658c-0.212,0-0.384,0.172-0.384,0.384l0.005,5.13c0,0.424,0.343,0.767,0.765,0.767
	c0.423,0,0.765-0.343,0.765-0.767V15.505z M2.708,21.33c0-0.424-0.342-0.768-0.765-0.768c-0.422,0-0.765,0.344-0.765,0.768
	l-0.005,3.796c0,0.21,0.172,0.383,0.384,0.383s1.151-1.108,1.151-1.657V21.33z`;
          break;
        case "Jeep":
          svg = `M7.654,0.02c0,0,4.557,0.026,5.797,0.666c1.238,0.64,1.889,2.923,1.889,2.923v30.499v0.008
	c0,0-0.45,1.107-1.731,1.51c-0.912,0.284-3.851,0.339-5.937,0.339c-2.086,0-5.022-0.055-5.936-0.339C0.453,35.223,0,34.113,0,34.113
	v-0.006V3.609c0,0,0.653-2.284,1.891-2.923C3.13,0.046,7.686,0.02,7.686,0.02 M7.668,9.875c2.847,0,5.153,0.335,5.153,0.335
	s1.377-2.483,1.377-4.037c0-1.553-6.53-1.441-6.53-1.441S1.14,4.621,1.14,6.174c0,1.554,1.356,4.037,1.356,4.037
	S4.821,9.875,7.668,9.875z M12.762,33.282c0,0.396,0.32,0.718,0.717,0.718s0.718-0.32,0.718-0.718v-6.269
	c0-0.396-0.321-0.72-0.718-0.72s-0.717,0.321-0.717,0.72V33.282z M1.145,33.282C1.145,33.678,1.466,34,1.862,34
	s0.718-0.32,0.718-0.718V16.789V13.17c0-0.516-0.881-1.556-1.079-1.556c-0.2,0-0.359,0.161-0.359,0.36l0.004,4.814L1.145,35.282
	V33.282z M14.197,12.332c0-0.396-0.321-0.718-0.718-0.718s-0.717,0.321-0.717,0.718v4.627c0,0.397,0.32,0.718,0.717,0.718
	s0.718-0.321,0.718-0.718V12.332z M8199.17,18.255 M-8183.831,18.255 M14.197,19.634c0-0.396-0.32-0.718-0.718-0.718
	c-0.396,0-0.718,0.32-0.718,0.718v4.628c0,0.396,0.322,0.719,0.718,0.719s0.718-0.32,0.718-0.719V19.634z M4.066,1.027
	c0,0-2.793,0.118-2.924,2.023C1.013,4.955,3.96,2.789,4.066,1.027z M14.197,3.048c-0.133-1.905-2.926-2.023-2.926-2.023
	C11.376,2.787,14.326,4.953,14.197,3.048z`;
          break;
        case "SUV/Van":
          svg = `M7.654,0.02c0,0,4.557,0.026,5.797,0.666c1.238,0.64,1.889,2.923,1.889,2.923v30.499v0.007
	c0,0-0.451,1.108-1.732,1.51c-0.912,0.284-3.85,0.339-5.937,0.339c-2.086,0-5.022-0.055-5.936-0.339C0.453,35.223,0,34.113,0,34.113
	v-0.006V3.609c0,0,0.653-2.284,1.891-2.923C3.13,0.046,7.686,0.02,7.686,0.02 M4.067,1.047c0,0-2.793,0.118-2.925,2.023
	C1.013,4.975,3.961,2.809,4.067,1.047z M14.197,3.068c-0.132-1.905-2.925-2.023-2.925-2.023C11.376,2.807,14.326,4.973,14.197,3.068
	z M7.597,12.73c2.819,0,5.103,0.333,5.103,0.333s1.362-2.459,1.362-3.997c0-1.539-6.465-1.427-6.465-1.427S1.133,7.528,1.133,9.067
	c0,1.538,1.343,3.997,1.343,3.997S4.778,12.73,7.597,12.73z M2.48,31.176c0,0-1.347,0.269-1.347,1.811s6.478,1.431,6.478,1.431
	s6.477,0.111,6.477-1.431s-1.367-1.811-1.367-1.811s-2.286,0.334-5.11,0.334C4.786,31.51,2.48,31.176,2.48,31.176z M12.597,18.619
	c0,0.414,0.336,0.75,0.75,0.75s0.75-0.336,0.75-0.75l0.006-5.032c0-0.208-0.168-0.377-0.377-0.377c-0.207,0-1.129,1.087-1.129,1.626
	V18.619z M12.607,29.313c0,0.54,0.92,1.626,1.129,1.626c0.207,0,0.375-0.168,0.375-0.376l-0.004-2.722
	c0-0.415-0.336-0.752-0.75-0.752c-0.416,0-0.75,0.337-0.75,0.752V29.313z M12.609,25.504c0,0.415,0.336,0.751,0.75,0.751
	s0.75-0.336,0.75-0.751v-4.66c0-0.414-0.336-0.75-0.75-0.75s-0.75,0.336-0.75,0.75V25.504z M2.658,14.836
	c0-0.539-0.922-1.626-1.129-1.626c-0.208,0-0.377,0.168-0.377,0.377l0.004,5.032c0,0.414,0.336,0.75,0.751,0.75
	c0.414,0,0.75-0.336,0.75-0.75V14.836z M2.648,27.841c0-0.415-0.335-0.752-0.75-0.752c-0.414,0-0.751,0.337-0.751,0.752
	l-0.004,2.722c0,0.208,0.168,0.376,0.376,0.376s1.129-1.086,1.129-1.626V27.841z M2.646,20.844c0-0.414-0.336-0.75-0.75-0.75
	c-0.415,0-0.75,0.336-0.75,0.75v4.66c0,0.415,0.336,0.751,0.75,0.751c0.414,0,0.75-0.336,0.75-0.751V20.844z`;
          break;
        case "Trailer Truck":
          svg = `M7.654,0.02c0,0,4.557,0.026,5.797,0.666c1.238,0.64,1.889,2.923,1.889,2.923v12.499v0.008
	c0,0-0.45,1.107-1.731,1.51c0.252,0-0.184,0,0,0c1.731,0,1.731,1.734,1.731,1.734s0,10.879,0,14.933C15.339,36,13.607,36,13.607,36
	s1.732,0,1.732,1.734c0,4.054,0,11.478,0,15.531C15.339,55,13.608,55,13.608,55H1.735C1.735,55,0,55,0,53.266
	c0-4.054,0-11.415,0-15.469C0,36,1.732,36,1.732,36H1.079l0,0h0.653C1.732,36,0,36,0,34.292c0-4.054,0-14.933,0-14.933
	s0-1.734,1.735-1.734c0.083,0-0.096,0,0,0C0.453,17.223,0,16.113,0,16.113v-0.006V3.609c0,0,0.653-2.284,1.891-2.923
	C3.13,0.046,7.686,0.02,7.686,0.02 M7.668,9.875c2.847,0,5.153,0.335,5.153,0.335s1.377-2.483,1.377-4.037
	c0-1.553-6.53-1.441-6.53-1.441S1.14,4.621,1.14,6.174c0,1.554,1.356,4.037,1.356,4.037S4.821,9.875,7.668,9.875z M1.145,15.282
	C1.145,15.678,1.466,16,1.862,16s0.718-0.32,0.718-0.718v-0.493V12.17c0-0.516-0.881-1.556-1.079-1.556
	c-0.2,0-0.359,0.161-0.359,0.36l0.004,3.814 M8199.17,18.255 M-8183.831,18.255 M4.066,1.027c0,0-2.793,0.118-2.924,2.023
	C1.013,4.955,3.96,2.789,4.066,1.027z M14.197,3.048c-0.133-1.905-2.926-2.023-2.926-2.023C11.376,2.787,14.326,4.953,14.197,3.048z
	 M14.193,14.788l0.004-3.814c0-0.199-0.159-0.36-0.359-0.36c-0.197,0-1.078,1.04-1.078,1.556v2.619v0.493
	c0,0.397,0.322,0.718,0.718,0.718s0.717-0.322,0.717-0.718`;
          break;
      }

      return svg;
    },
  },
};
