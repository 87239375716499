<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-btn icon :x-small="xSmall" v-on="on" @click="clickIcon()">
        <svg
          id="Layer_1"
          :width="xSmall ? 15 : 20"
          :height="xSmall ? 15 : 20"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 93.73 130"
        >
          <path
            d="M54.61,111.8a36,36,0,0,1,1-11.7A36.38,36.38,0,0,1,68,80.86a35.89,35.89,0,0,1,20.84-8.1c.76-.05,1.52-.08,2.27-.08a36.41,36.41,0,0,1,24.28,9.37V11.17l-2.67,2.67-3.79-3.78-3.78,3.78-3.6-3.6L98,13.84l-3.78-3.78-3.79,3.78-3.61-3.61-3.62,3.61-3.78-3.78-3.78,3.78L72,10.24l-3.61,3.6L64.6,10.06l-3.78,3.78L57,10l-3.84,3.84-3.78-3.78-3.78,3.78L42,10.24l-3.6,3.6-3.78-3.78-3.78,3.78-2.68-2.67V132.82l2.68-2.67,3.78,3.78,3.78-3.78,3.6,3.6,3.61-3.6,3.78,3.78,3.78-3.78L57,134l3.84-3.84.81.8A35.84,35.84,0,0,1,54.61,111.8Z"
            transform="translate(-28.13 -10)"
            fill="#f59422"
          />
          <path
            d="M55.76,29.93H53a1.25,1.25,0,1,1,0-2.49h2.8a1.25,1.25,0,1,1,0,2.49Z"
            transform="translate(-28.13 -10)"
            fill="#fff"
          />
          <path
            d="M80.86,29.93H74.58a1.25,1.25,0,1,1,0-2.49h6.28a1.25,1.25,0,0,1,0,2.49Zm-12.55,0H62a1.25,1.25,0,1,1,0-2.49h6.27a1.25,1.25,0,1,1,0,2.49Z"
            transform="translate(-28.13 -10)"
            fill="#fff"
          />
          <path
            d="M89.93,29.93h-2.8a1.25,1.25,0,1,1,0-2.49h2.8a1.25,1.25,0,1,1,0,2.49Z"
            transform="translate(-28.13 -10)"
            fill="#fff"
          />
          <path
            d="M38.34,42.14H35.53a1.25,1.25,0,0,1,0-2.5h2.81a1.25,1.25,0,0,1,0,2.5Z"
            transform="translate(-28.13 -10)"
            fill="#fff"
          />
          <path
            d="M98.53,42.14h-6a1.25,1.25,0,0,1,0-2.5h6a1.25,1.25,0,0,1,0,2.5Zm-12,0h-6a1.25,1.25,0,0,1,0-2.5h6a1.25,1.25,0,0,1,0,2.5Zm-12,0h-6a1.25,1.25,0,0,1,0-2.5h6a1.25,1.25,0,0,1,0,2.5Zm-12,0h-6a1.25,1.25,0,1,1,0-2.5h6a1.25,1.25,0,0,1,0,2.5Zm-12,0h-6a1.25,1.25,0,0,1,0-2.5h6a1.25,1.25,0,1,1,0,2.5Z"
            transform="translate(-28.13 -10)"
            fill="#fff"
          />
          <path
            d="M107.36,42.14h-2.81a1.25,1.25,0,0,1,0-2.5h2.81a1.25,1.25,0,0,1,0,2.5Z"
            transform="translate(-28.13 -10)"
            fill="#fff"
          />
          <path
            d="M38.34,52.44H35.53a1.25,1.25,0,0,1,0-2.5h2.81a1.25,1.25,0,0,1,0,2.5Z"
            transform="translate(-28.13 -10)"
            fill="#fff"
          />
          <path
            d="M98.53,52.44h-6a1.25,1.25,0,0,1,0-2.5h6a1.25,1.25,0,0,1,0,2.5Zm-12,0h-6a1.25,1.25,0,0,1,0-2.5h6a1.25,1.25,0,1,1,0,2.5Zm-12,0h-6a1.25,1.25,0,0,1,0-2.5h6a1.25,1.25,0,0,1,0,2.5Zm-12,0h-6a1.25,1.25,0,0,1,0-2.5h6a1.25,1.25,0,0,1,0,2.5Zm-12,0h-6a1.25,1.25,0,0,1,0-2.5h6a1.25,1.25,0,0,1,0,2.5Z"
            transform="translate(-28.13 -10)"
            fill="#fff"
          />
          <path
            d="M107.36,52.44h-2.81a1.25,1.25,0,0,1,0-2.5h2.81a1.25,1.25,0,1,1,0,2.5Z"
            transform="translate(-28.13 -10)"
            fill="#fff"
          />
          <path
            d="M38.34,61.88H35.53a1.25,1.25,0,0,1,0-2.49h2.81a1.25,1.25,0,1,1,0,2.49Z"
            transform="translate(-28.13 -10)"
            fill="#fff"
          />
          <path
            d="M62.58,61.88H56.52a1.25,1.25,0,1,1,0-2.49h6.06a1.25,1.25,0,1,1,0,2.49Zm-12.12,0H44.4a1.25,1.25,0,1,1,0-2.49h6.06a1.25,1.25,0,1,1,0,2.49Z"
            transform="translate(-28.13 -10)"
            fill="#fff"
          />
          <path
            d="M71.45,61.88H68.64a1.25,1.25,0,1,1,0-2.49h2.81a1.25,1.25,0,0,1,0,2.49Z"
            transform="translate(-28.13 -10)"
            fill="#fff"
          />
          <path
            d="M92.73,61.88H89.92a1.25,1.25,0,0,1,0-2.49h2.81a1.25,1.25,0,1,1,0,2.49Z"
            transform="translate(-28.13 -10)"
            fill="#fff"
          />
          <path
            d="M101,61.88H96.85a1.25,1.25,0,1,1,0-2.49H101a1.25,1.25,0,0,1,0,2.49Z"
            transform="translate(-28.13 -10)"
            fill="#fff"
          />
          <path
            d="M107.89,61.88h-2.81a1.25,1.25,0,0,1,0-2.49h2.81a1.25,1.25,0,1,1,0,2.49Z"
            transform="translate(-28.13 -10)"
            fill="#fff"
          />
          <path
            d="M38.34,70.8H35.53a1.25,1.25,0,0,1,0-2.5h2.81a1.25,1.25,0,0,1,0,2.5Z"
            transform="translate(-28.13 -10)"
            fill="#fff"
          />
          <path
            d="M62.58,70.8H56.52a1.25,1.25,0,1,1,0-2.5h6.06a1.25,1.25,0,0,1,0,2.5Zm-12.12,0H44.4a1.25,1.25,0,1,1,0-2.5h6.06a1.25,1.25,0,0,1,0,2.5Z"
            transform="translate(-28.13 -10)"
            fill="#fff"
          />
          <path
            d="M71.45,70.8H68.64a1.25,1.25,0,0,1,0-2.5h2.81a1.25,1.25,0,0,1,0,2.5Z"
            transform="translate(-28.13 -10)"
            fill="#fff"
          />
          <path
            d="M92.73,70.8H89.92a1.25,1.25,0,0,1,0-2.5h2.81a1.25,1.25,0,0,1,0,2.5Z"
            transform="translate(-28.13 -10)"
            fill="#fff"
          />
          <path
            d="M101,70.8H96.85a1.25,1.25,0,0,1,0-2.5H101a1.25,1.25,0,0,1,0,2.5Z"
            transform="translate(-28.13 -10)"
            fill="#fff"
          />
          <path
            d="M107.89,70.8h-2.81a1.25,1.25,0,0,1,0-2.5h2.81a1.25,1.25,0,0,1,0,2.5Z"
            transform="translate(-28.13 -10)"
            fill="#fff"
          />
          <path
            d="M38.44,82.79H35.63a1.25,1.25,0,1,1,0-2.49h2.81a1.25,1.25,0,1,1,0,2.49Z"
            transform="translate(-28.13 -10)"
            fill="#fff"
          />
          <path
            d="M56,82.79H51.61a1.25,1.25,0,0,1,0-2.49H56a1.25,1.25,0,1,1,0,2.49Zm-8.78,0H42.83a1.25,1.25,0,1,1,0-2.49h4.39a1.25,1.25,0,1,1,0,2.49Z"
            transform="translate(-28.13 -10)"
            fill="#fff"
          />
          <path
            d="M63.2,82.79H60.4a1.25,1.25,0,1,1,0-2.49h2.8a1.25,1.25,0,1,1,0,2.49Z"
            transform="translate(-28.13 -10)"
            fill="#fff"
          />
          <path
            d="M60.24,109.23c.32-17.3,13.69-30.78,30.85-30.76a30.77,30.77,0,1,1,0,61.53C73.79,140,60.55,126.41,60.24,109.23Zm30.88,4.85a2.5,2.5,0,0,0,.2.3l6.35,6.76a1.59,1.59,0,0,0,2.53,0c.75-.78,1.49-1.57,2.21-2.38a1.82,1.82,0,0,0,0-2.57c-1.47-1.58-3-3.15-4.43-4.72l-2.18-2.34c.22-.24.39-.44.57-.62,2-2.15,4-4.29,6-6.45a1.85,1.85,0,0,0,0-2.7c-.67-.72-1.34-1.45-2-2.17a1.66,1.66,0,0,0-2.7,0c-.59.62-1.17,1.26-1.75,1.88l-4.77,5.08-2.86-3c-1.22-1.31-2.43-2.63-3.66-3.92a1.66,1.66,0,0,0-2.67,0q-1.06,1.09-2.1,2.25a1.89,1.89,0,0,0,0,2.67c1.48,1.6,3,3.18,4.47,4.77l2.13,2.28-.58.63-6,6.36a1.89,1.89,0,0,0,0,2.79c.68.75,1.37,1.48,2.06,2.21a1.61,1.61,0,0,0,2.58,0c.68-.71,1.35-1.44,2-2.16Z"
            transform="translate(-28.13 -10)"
            fill="#f04d4d"
          />
        </svg>
      </v-btn>
    </template>
    <span>Close Ticket</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    xSmall: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clickIcon() {
      this.$emit("click");
    }
  }
};
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>