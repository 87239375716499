var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('img',{staticStyle:{"position":"fixed"},attrs:{"width":"100%","height":"100%","src":require('./assets/white_bg.png')}}),(_vm.$store.state.token)?_c('user-toolbar'):_vm._e(),_c('v-main',{on:{"scroll":_vm.handleScroll}},[_c('router-view')],1),(_vm.$store.state.loading)?_c('page-loading'):_vm._e(),(_vm.$store.state.unauthorized)?_c('page-unauthorized'):_vm._e(),_c('info-bar'),_c('v-fab-transition',[(
        _vm.offsetTop === 0 &&
          _vm.$store.state.showChevronButtons &&
          _vm.$store.state.hasItems
      )?_c('v-btn',{ref:"button",attrs:{"fixed":"","dark":"","right":"","bottom":"","fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.$vuetify.goTo(_vm.pageHeight)}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-chevron-down")])],1):_vm._e()],1),_c('v-fab-transition',[(
        _vm.offsetTop > 100 &&
          _vm.$store.state.showChevronButtons &&
          _vm.$store.state.hasItems
      )?_c('v-btn',{ref:"button",attrs:{"fixed":"","dark":"","right":"","bottom":"","fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.$vuetify.goTo(0)}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-chevron-up")])],1):_vm._e()],1),(_vm.$store.state.token === null)?_c('v-footer',{staticStyle:{"position":"absolute","right":"1px","bottom":"5px"},attrs:{"padless":""}},[_c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-card-text',{staticClass:"py-2"},[_c('strong',[_vm._v(_vm._s(("" + _vm.version)))])])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }