<template>
  <v-text-field
    v-model="search"
    :dark="dark"
    :color="color"
    :label="title"
    dense
    clearable
    append-icon="mdi-magnify"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Search"
    },
    color: {
      type: String,
      default: "primary"
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    timeOut: null
  }),
  computed: {
    search: {
      get() {
        return "";
      },
      set(value) {
        clearTimeout(this.timeOut);
        this.timeOut = setTimeout(() => {
          this.$emit("search", value);
        }, 500);
      }
    }
  }
};
</script>

<style scoped>
</style>