<template>
  <div class="App" :style="googleMapsStyles"></div>
</template>

<script>
import gmapsInit from "@/utils/gmaps";
import MarkerClusterer from "@google/markerclusterer";

export default {
  name: "App",
  props: {
    height: {
      type: String,
      default: "100%",
    },
    width: {
      type: String,
      default: "auto",
    },
  },
  data() {
    return {
      map: null,
      marker: [],
      outletMarkers: [],
      boundsMarkers: [],
      snailtrailMarkers: [],
      cluster: null,
      forCluster: [],
      editPolygon: null,
      polygonCoords: [],
      polygonCoordsModel: [],
      editablePolygonCoords: [],
      editablePolygonCoordsModel: [],
      trafficLayer: null,
      inDashboard: false,
    };
  },
  computed: {
    plottedOutletData() {
      return this.polygonCoords;
    },
    plottedOutletModel() {
      return this.polygonCoordsModel;
    },
    editPlottedOutletData() {
      return this.editablePolygonCoords;
    },
    editPlottedOutletModel() {
      return this.editablePolygonCoordsModel;
    },
    googleMapsStyles() {
      return {
        "--height": this.height,
        "--width": this.width,
      };
    },
  },
  watch: {
    plottedGeozoneModel: function() {
      if (this.polygonCoordsModel.length !== 0) {
        this.drawingManager.setOptions({
          drawingControl: false,
        });
      } else {
        this.drawingManager.setOptions({
          drawingControl: true,
        });
      }
    },
    plottedEditStopModel: function() {
      if (this.editablePolygonCoordsModel.length !== 0) {
        this.drawingManager.setOptions({
          drawingControl: false,
        });
      } else {
        this.drawingManager.setOptions({
          drawingControl: true,
        });
      }
    },
  },
  methods: {
    async initializeMap() {
      try {
        const google = await gmapsInit();
        this.map = new google.maps.Map(this.$el, {
          center: { lat: 14.4793, lng: 121.0198 },
          zoom: 6,
          mapId: "392dcc2cd6c405a9",
          mapTypeControl: true,
          mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: google.maps.ControlPosition.TOP_RIGHT,
          },
          fullscreenControl: true,
          fullscreenControlOptions: {
            position: google.maps.ControlPosition.RIGHT_BOTTOM,
          },
          streetViewControl: true,
          streetViewControlOptions: {
            position: google.maps.ControlPosition.RIGHT_TOP,
          },
          zoomControl: true,
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_TOP,
          },
          styles: [
            {
              featureType: "landscape.natural",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "poi",
              stylers: [{ visibility: "off" }],
            },
          ],
        });

        this.trafficLayer = new google.maps.TrafficLayer();
      } catch (error) {
        console.error(error);
      }
    },
    async autoComplete() {
      // const pm = this;
      const google = await gmapsInit();
      let map = this.map;
      let input = document.getElementById("searchPlace");
      let autocomplete = new google.maps.places.Autocomplete(input);

      autocomplete.bindTo("bounds", this.map);

      autocomplete.setFields([
        "address_components",
        "geometry",
        "icon",
        "name",
      ]);
      let infowindow = new google.maps.InfoWindow();
      let infowindowContent = document.getElementById("infowindow-content");
      infowindow.setContent(infowindowContent);

      let marker = new google.maps.Marker({
        map: map,
        anchorPoint: new google.maps.Point(0, -29),
        draggable: true,
      });

      autocomplete.addListener("place_changed", function() {
        infowindow.close();
        marker.setVisible(false);
        let place = autocomplete.getPlace();
        if (!place.geometry) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          // window.alert("No details available for input: '" + place.name + "'");
          // return;
        }

        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
          map.setZoom(22); // Why 17? Because it looks good.
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(22); // Why 17? Because it looks good.
        }

        marker.setPosition(place.geometry.location);

        marker.setVisible(true);

        let address = "";
        if (place.address_components) {
          address = [
            (place.address_components[0] &&
              place.address_components[0].short_name) ||
              "",
            (place.address_components[1] &&
              place.address_components[1].short_name) ||
              "",
            (place.address_components[2] &&
              place.address_components[2].short_name) ||
              "",
          ].join(" ");
        }

        infowindowContent.children["place-icon"].src = place.icon;
        infowindowContent.children["place-name"].textContent = place.name;
        infowindowContent.children["place-address"].textContent = address;
        infowindow.open(map, marker);
      });
    },
    async editAutoComplete() {
      const pm = this;
      const google = await gmapsInit();
      let map = pm.map;

      let input = document.getElementById("editSearchPlace");
      let autocomplete = new google.maps.places.Autocomplete(input);

      autocomplete.bindTo("bounds", this.map);

      autocomplete.setFields([
        "address_components",
        "geometry",
        "icon",
        "name",
      ]);

      let infowindow = new google.maps.InfoWindow();
      let infowindowContent = document.getElementById(
        "edit-infowindow-content"
      );
      infowindow.setContent(infowindowContent);

      let marker = new google.maps.Marker({
        map: map,
        anchorPoint: new google.maps.Point(0, -29),
        draggable: true,
      });

      autocomplete.addListener("place_changed", () => {
        infowindow.close();
        marker.setVisible(false);
        let place = autocomplete.getPlace();
        if (!place.geometry) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          // window.alert("No details available for input: '" + place.name + "'");
          // return;
        }

        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
          map.setZoom(22); // Why 17? Because it looks good.
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(20); // Why 17? Because it looks good.
        }

        marker.setPosition(place.geometry.location);

        marker.setVisible(true);

        let address = "";
        if (place.address_components) {
          address = [
            (place.address_components[0] &&
              place.address_components[0].short_name) ||
              "",
            (place.address_components[1] &&
              place.address_components[1].short_name) ||
              "",
            (place.address_components[2] &&
              place.address_components[2].short_name) ||
              "",
          ].join(" ");
        }

        infowindowContent.children["edit-place-icon"].src = place.icon;
        infowindowContent.children["edit-place-name"].textContent = place.name;
        infowindowContent.children["edit-place-address"].textContent = address;
        infowindow.open(map, marker);
      });
    },
    async editPlottedOutlet(data) {
      const pm = this;
      const google = await gmapsInit();
      const map = pm.map;

      this.editablePolygonCoords = data;

      this.editPolygon = new google.maps.Polygon({
        paths: this.editablePolygonCoords,
        strokeWeight: 5,
        fillOpacity: 0.35,
        editable: true,
        draggable: true,
      });

      this.editPolygon.setMap(map);

      this.editPolygon.getPaths().forEach((path) => {
        google.maps.event.addListener(path, "set_at", () => {
          // Point was moved
          this.editablePolygonCoords.length = 0;
          for (let i = 0; i < path.length; i++) {
            this.editablePolygonCoords.push({
              lat: path.getAt(i).lat(),
              lng: path.getAt(i).lng(),
            });
          }
        });
      });

      google.maps.event.addListener(this.editPolygon, "dragend", () => {
        // Polygon was dragged
        this.editablePolygonCoords.length = 0;
        this.editPolygon.getPaths().forEach((path) => {
          for (let i = 0; i < path.length; i++) {
            this.editablePolygonCoords.push({
              lat: path.getAt(i).lat(),
              lng: path.getAt(i).lng(),
            });
          }
        });
      });
    },
    removeEditDrawnPolygon() {
      let lastOverlayOne = this.editPolygon;
      let lastOverlayTwo = this.editablePolygonCoordsModel.pop();
      if (lastOverlayOne || lastOverlayTwo) {
        if (lastOverlayOne) {
          lastOverlayOne.setMap(null);
        }

        if (lastOverlayTwo) {
          lastOverlayTwo.setMap(null);
        }

        this.editablePolygonCoords.length = 0;
      }
    },
    async plotPolygons(data, color) {
      const pm = this;
      const google = await gmapsInit();
      const map = pm.map;

      let thePolygon = new google.maps.Polygon({
        paths: data,
        // strokeColor: "#FF0000",
        strokeColor: color,
        strokeOpacity: 0.5,
        strokeWeight: 1,
        // fillColor: "#FF0000",
        fillColor: color,
        fillOpacity: 0.2,
      });

      thePolygon.setMap(map);
    },
    async previewDrewOutletMarker(data) {
      // Automatic puts marker in the center of the Polygon, don't remove

      const google = await gmapsInit();

      let firstBounds = new google.maps.LatLngBounds();

      for (let i = 0; i < data.length; i++) {
        firstBounds.extend(data[i]);
      }

      let iconBase = {
        url:
          "https://storage.googleapis.com/support-kms-prod/SNP_2752125_en_v0",
        scaledSize: new google.maps.Size(10, 10),
      };

      let marker = new google.maps.Marker({
        map: this.map,
        icon: iconBase,
      });

      marker.setPosition(firstBounds.getCenter());
      this.map.setCenter(firstBounds.getCenter());
      this.map.setZoom(20);
    },
    async drawPolygon(data) {
      const pm = this;
      const google = await gmapsInit();
      const map = pm.map;

      // This is the Drawing Manager, this is very important, don't remove...

      let drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: null,
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: ["polygon"],
        },
        polygonOptions: {
          fillOpacity: 0.35,
          strokeWeight: 5,
          clickable: false,
          editable: true,
          zIndex: 1,
        },
      });

      drawingManager.setMap(map);

      // This is the addListener to get the coordinates of the polygon created, don't remove...
      let coordinates = [];
      let coordinatesModel = [];
      google.maps.event.addListener(
        drawingManager,
        "polygoncomplete",
        (polygon) => {
          let path = polygon.getPath();

          for (let i = 0; i < path.length; i++) {
            coordinates.push({
              lat: path.getAt(i).lat(),
              lng: path.getAt(i).lng(),
            });
          }
          coordinatesModel.push(polygon);
          drawingManager.setDrawingMode(null);

          if (data === "edit_stop") {
            this.editablePolygonCoords = coordinates;
            this.editablePolygonCoordsModel = coordinatesModel;
          } else {
            this.polygonCoords = coordinates;
            this.polygonCoordsModel = coordinatesModel;
          }
        }
      );

      this.drawingManager = drawingManager;
    },
    removeDrawnPolygon() {
      let lastOverlay = this.polygonCoordsModel.pop();
      if (lastOverlay) {
        this.polygonCoords.length = 0;
        lastOverlay.setMap(null);
      }
    },
    async clickSpecificOutlet(id) {
      const google = await gmapsInit();

      google.maps.event.trigger(this.outletMarkers[id], "click");
    },
    async clickVehicle(id) {
      const google = await gmapsInit();

      google.maps.event.trigger(this.marker[id], "click");
    },
    async clickSnailtrail(id) {      
      const google = await gmapsInit();
      console.log(id)
      google.maps.event.trigger(this.snailtrailMarkers[id], "click");
    },
    async insertMultipleMarker(data) {
      this.forCluster.length = 0;
      // Automatic puts marker in the center of the Polygon, don't remove
      const google = await gmapsInit();
      const pm = this;
      const map = pm.map;

      let infowindow = new google.maps.InfoWindow();

      data.forEach((item) => {
        this.inDashboard = item.in_dashboard;

        if (this.inDashboard) {
          let iconBase = {
            url: item.image_url,
            scaledSize: new google.maps.Size(40, 35),
          };

          this.marker[item.id] = new google.maps.Marker({
            position: item.coordinates,
            map: map,
            icon: iconBase,
          });

          this.forCluster.push(this.marker[item.id]);

          if (item.content !== ``) {
            infowindow.setContent(item.content);
          }
          infowindow.open(map, this.marker[item.id]);
        }

        if (!this.inDashboard) {
          // let secondPrev = new google.maps.Marker({
          //   position: item.second_previous_coordinates,
          //   map: map,
          // });

          // let prev = new google.maps.Marker({
          //   position: item.previous_coordinates,
          //   map: map,
          // });

          const path = [
            item.second_previous_coordinates.lat &&
            item.second_previous_coordinates.lng
              ? item.second_previous_coordinates
              : item.second_previous_coordinates.lat &&
                item.second_previous_coordinates.lng
              ? item.previous_coordinates
              : item.coordinates,
            item.previous_coordinates.lat && item.previous_coordinates.lng
              ? item.previous_coordinates
              : item.coordinates,
          ];

          let heading = google.maps.geometry.spherical.computeHeading(
            path[0],
            path[1]
          );

          const MarkerWithLabel = require("markerwithlabel")(google.maps);

          this.marker[item.id] = new MarkerWithLabel({
            map: map,
            position: item.coordinates,
            labelContent: item.label_content,
            labelAnchor: new google.maps.Point(
              item.type_of_vehicle == "Default" ||
              item.type_of_vehicle == "Motorcycle" ||
              item.type_of_vehicle == "Cargo Ship"
                ? 0
                : 0,
              item.type_of_vehicle == "Default" ||
              item.type_of_vehicle == "Motorcycle" ||
              item.type_of_vehicle == "Cargo Ship"
                ? 60
                : 50
            ),
            // labelClass: item.label_class, // the CSS class for the label
            labelClass: "markerLabel",
            // icon: iconBase,
          });

          if (
            item.type_of_vehicle == "Default" ||
            item.type_of_vehicle == "Motorcycle" ||
            item.type_of_vehicle == "Cargo Ship"
          ) {
            this.marker[item.id].setIcon({
              // path:
              //   "M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z",
              url: item.path,
              scaledSize: new google.maps.Size(
                item.markerSize.x,
                item.markerSize.y
              ),
            });
          } else {
            this.marker[item.id].setIcon({
              // path:
              //   "M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z",
              // url: item.image_url,
              path: item.path,
              strokeColor: "#444444",
              strokeWeight: 1,
              fillOpacity: 1,
              fillColor: item.color,
              scale: 1,
              rotation: heading,
              anchor: new google.maps.Point(10, 20),
            });
          }

          this.forCluster.push(this.marker[item.id]);

          google.maps.event.addListener(
            this.marker[item.id],
            "click",
            ((marker, indexID) => {
              return () => {
                map.setZoom(17);
                map.setCenter(marker[indexID].getPosition());
                // marker[indexID].setAnimation(google.maps.Animation.BOUNCE);

                if (item.content !== ``) {
                  infowindow.setContent(item.content);
                }
                infowindow.open(map, marker[indexID]);
                setTimeout(() => {
                  marker[indexID].setAnimation(null);
                }, 2100);
              };
            })(this.marker, item.id)
          );
        }
      });
    },
    async boundsMarker(data) {
      const google = await gmapsInit();

      const infowindow = new google.maps.InfoWindow();

      data.forEach((item) => {
        let firstBounds = new google.maps.LatLngBounds();

        for (let i = 0; i < item.coordinates.length; i++) {
          firstBounds.extend(item.coordinates[i]);
        }

        let iconBase = {
          url: item.image_url,
          scaledSize: new google.maps.Size(30, 30),
        };

        this.boundsMarkers[item.id] = new google.maps.Marker({
          map: this.map,
          position: firstBounds.getCenter(),
          icon: iconBase,
        });

        google.maps.event.addListener(
          this.boundsMarkers[item.id],
          "click",
          ((marker, indexID) => {
            return () => {
              this.map.fitBounds(firstBounds);
              this.map.setCenter(firstBounds.getCenter());
              if (item.content !== ``) {
                infowindow.setContent(item.content);
              }
              infowindow.open(this.map, marker[indexID]);
            };
          })(this.boundsMarkers, item.id)
        );
      });
    },
    async boundsMarkerOutlet(data) {
      const google = await gmapsInit();

      const infowindow = new google.maps.InfoWindow();

      data.forEach((item) => {
        let firstBounds = new google.maps.LatLngBounds();

        for (let i = 0; i < item.coordinates.length; i++) {
          firstBounds.extend(item.coordinates[i]);
        }

        let iconBase = {
          url: item.image_url,
          scaledSize: new google.maps.Size(30, 30),
        };

        this.outletMarkers[item.id] = new google.maps.Marker({
          map: this.map,
          position: firstBounds.getCenter(),
          icon: iconBase,
        });

        google.maps.event.addListener(
          this.outletMarkers[item.id],
          "click",
          ((marker, indexID) => {
            return () => {
              this.map.setZoom(17);
              // this.map.fitBounds(firstBounds);
              this.map.setCenter(firstBounds.getCenter());
              if (item.content !== ``) {
                infowindow.setContent(item.content);
              }
              infowindow.open(this.map, marker[indexID]);
            };
          })(this.outletMarkers, item.id)
        );
      });
    },
    async boundsSnailtrailMarker(data) {
      const google = await gmapsInit();

      const infowindow = new google.maps.InfoWindow();

      data.forEach((item) => {
        this.snailtrailMarkers[item.id] = new google.maps.Marker({
          map: this.map,
          position: { lat: item.lat, lng: item.lng },
        });

        const path = [
          this.snailtrailMarkers[
            item.id !== 0 ? item.id - 1 : item.id
          ].getPosition(),
          this.snailtrailMarkers[item.id].getPosition(),
        ];

        const heading = google.maps.geometry.spherical.computeHeading(
          path[0],
          path[1]
        );

        const icon = {
          path: "M12,2L4.5,20.29L5.21,21L12,18L18.79,21L19.5,20.29L12,2Z",
          scale: 0.5,
          strokeColor: "black",
          strokeWeight: 1,
          fillOpacity: 1,
          fillColor: this.getColorStats(item.remarks),
          offset: "5%",
          rotation: heading,
          anchor: new google.maps.Point(10, 25), // orig 10,50 back of marker, 10,0 front of marker, 10,25 center of marker
        };

        this.snailtrailMarkers[item.id].setIcon(icon);

        google.maps.event.addListener(
          this.snailtrailMarkers[item.id],
          "click",
          ((marker, indexID) => {
            return () => {
              this.map.setCenter({ lat: item.lat, lng: item.lng });
              if (item.content !== ``) {
                infowindow.setContent(item.content);
              }
              infowindow.open(this.map, marker[indexID]);
            };
          })(this.snailtrailMarkers, item.id)
        );
      });
    },
    async boundsOfAllMarkers(data) {
      const google = await gmapsInit();

      const map = this.map;

      let secondBounds = new google.maps.LatLngBounds();

      data.forEach((datum) => {
        secondBounds.extend(datum);
      });

      this.map.fitBounds(secondBounds);

      const listener = google.maps.event.addListener(map, "idle", function() {
        if (map.getZoom() > 15) map.setZoom(15);
        google.maps.event.removeListener(listener);
      });
    },
    async markerCluster() {
      this.cluster = new MarkerClusterer(this.map, this.forCluster, {
        imagePath:
          "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
      });
    },
    removeMarkers() {
      for (let i = 0; i < this.forCluster.length; i++) {
        this.forCluster[i].setMap(null);
      }

      this.forCluster.length = 0;
    },
    removeClusterMarker() {
      if (this.cluster) {
        this.cluster.clearMarkers();
      }
    },
    trafficMode(status) {
      if (status) {
        this.trafficLayer.setMap(this.map);
      } else {
        this.trafficLayer.setMap(null);
      }
    },
    // async viewSnailTrail(data) {
    //   // Automatic puts marker in the center of the Polygon, don't remove

    //   const google = await gmapsInit();

    //   const map = this.map;

    //   // let infowindow = new google.maps.InfoWindow();

    //   if (data.length !== 0) {
    //     this.snailtrailMarkers.length = 0;

    //     const flightPlanCoordinates = await Promise.all(
    //       data.map((item) => {
    //         return {
    //           lat: item.lat,
    //           lng: item.lng,
    //         };
    //       })
    //     );

    //     const flightPlanColorization = await Promise.all(
    //       data.map((item) => {
    //         return {
    //           remarks: item.remarks,
    //           coordinates: {
    //             lat: item.lat,
    //             lng: item.lng,
    //           },
    //         };
    //       })
    //     );

    //     const flightPath = new google.maps.Polyline({
    //       path: flightPlanCoordinates,
    //       geodesic: true,
    //       // strokeColor: "#FF0000",
    //       strokeOpacity: 1.0,
    //       strokeWeight: 2,
    //     });

    //     await Promise.all(
    //       flightPlanColorization.map((item) => {
    //         flightPath.setOptions({
    //           strokeColor:
    //             item.remarks == "RUNNING"
    //               ? "#008b0d"
    //               : item.remarks == "STOPPED"
    //               ? "#940000"
    //               : "#000000",
    //         });
    //       })
    //     );

    //     flightPath.setMap(map);
    //   } else {
    //     await this.initializeMap();
    //   }
    // },
    async viewSnailTrail(data) {
      // Automatic puts marker in the center of the Polygon, don't remove

      const google = await gmapsInit();

      const map = this.map;

      if (!data) {
        await this.initializeMap();
      }

      // const service = new google.maps.DirectionsService();

      let infowindow = new google.maps.InfoWindow();

      this.snailtrailMarkers.length = 0;
      // let parts = [];

      // Zoom and center map automatically by stations (each station will be in visible map area)
      let lngs = data.map(function(station) {
        return station.lng;
      });
      let lats = data.map(function(station) {
        return station.lat;
      });
      map.fitBounds({
        west: Math.min.apply(null, lngs),
        east: Math.max.apply(null, lngs),
        north: Math.min.apply(null, lats),
        south: Math.max.apply(null, lats),
      });

      // Show stations on the map as markers
      for (let i = 0; i < data.length; i++) {
        this.snailtrailMarkers[i] = new google.maps.Marker({
          position: data[i],
          map: map,
          // icon: iconBase,
          shadow: "http://www.google.com/mapfiles/shadow50.png",
        });

        const path = [
          this.snailtrailMarkers[i !== 0 ? i - 1 : i].getPosition(),
          this.snailtrailMarkers[i].getPosition(),
        ];

        // const heading = google.maps.geometry.spherical.computeHeading(
        //   path[0],
        //   path[1]
        // );

        const heading = google.maps.geometry.spherical.computeHeading(
          path[1],
          path[0]
        );

        // var car =
        //   "M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z";

        const icon = {
          path: "M12,2L4.5,20.29L5.21,21L12,18L18.79,21L19.5,20.29L12,2Z",
          scale: 0.5,
          strokeColor: "black",
          strokeWeight: 1,
          fillOpacity: 1,
          fillColor: this.getColorStats(data[i].remarks),
          offset: "5%",
          rotation: heading,
          anchor: new google.maps.Point(10, 25), // orig 10,50 back of marker, 10,0 front of marker, 10,25 center of marker
        };

        this.snailtrailMarkers[i].setIcon(icon);

        google.maps.event.addListener(
          this.snailtrailMarkers[i],
          "click",
          ((marker, indexID) => {
            return () => {
              // this.map.setZoom(20);
              this.map.setCenter(marker[indexID].getPosition());
              if (data[i].content !== ``) {
                infowindow.setContent(data[i].content);
              }
              infowindow.open(this.map, marker[indexID]);
            };
          })(this.snailtrailMarkers, [i])
        );
      }

      // // Divide route to several parts because max stations limit is 25 (23 waypoints + 1 origin + 1 destination)
      // for (let i = 0, max = 25 - 1; i < data.length; i = i + max)
      //   parts.push(data.slice(i, i + max + 1));

      // // Service callback to process service results
      // let service_callback = function(response, status) {
      //   if (status != "OK") {
      //     console.log("Directions request failed due to " + status);
      //     return;
      //   }
      //   let renderer = new google.maps.DirectionsRenderer();
      //   renderer.setMap(map);
      //   renderer.setOptions({ suppressMarkers: true, preserveViewport: true });
      //   renderer.setDirections(response);
      // };

      // // Send requests to service to get route (for stations count <= 25 only one request will be sent)
      // for (let i = 0; i < parts.length; i++) {
      //   // Waypoints does not include first station (origin) and last station (destination)
      //   let waypoints = [];
      //   for (let j = 1; j < parts[i].length - 1; j++)
      //     waypoints.push({ location: parts[i][j], stopover: false });
      //   // Service options
      //   const service_options = {
      //     origin: parts[i][0],
      //     destination: parts[i][parts[i].length - 1],
      //     waypoints: waypoints,
      //     travelMode: "DRIVING",
      //   };
      //   // Send request
      //   service.route(service_options, service_callback);
      // }
    },
    getColorStats(message) {
      if (message.toLowerCase() == "running") {
        return "#008b0d";
      } else if (message.toLowerCase() == "stopped") {
        return "#940000";
      }
      return "#000000";
    },
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}

.App {
  width: var(--width);
  height: var(--height);
}

.markerLabel {
  color: black;
  background-color: #ffffff;
  font-family: "Lucida Grande", "Arial", sans-serif;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  padding: 5px 10px;
  width: auto;
  white-space: nowrap;
  border-radius: 10px;
  border: 1px solid #616161;
  transform: translateX(-50%);
}

.hasRunninglabels {
  color: white;
  background-color: #008b0d;
  font-family: "Lucida Grande", "Arial", sans-serif;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  padding: 5px 0;
  width: 180px;
  white-space: nowrap;
  border-radius: 3px;
  border: 2px solid #616161;
}

.hasStoppedlabels {
  color: white;
  background-color: #940000;
  font-family: "Lucida Grande", "Arial", sans-serif;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  padding: 5px 0;
  width: 180px;
  white-space: nowrap;
  border-radius: 3px;
  border: 2px solid #616161;
}

.hasDecommissionedlabels {
  color: white;
  background-color: #e91e63;
  font-family: "Lucida Grande", "Arial", sans-serif;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  padding: 5px 0;
  width: 180px;
  white-space: nowrap;
  border-radius: 3px;
  border: 2px solid #616161;
}

.hasGPSForServicelabels {
  color: white;
  background-color: #9e9e9e;
  font-family: "Lucida Grande", "Arial", sans-serif;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  padding: 5px 0;
  width: 180px;
  white-space: nowrap;
  border-radius: 3px;
  border: 2px solid #616161;
}

.hasDelaylabels {
  color: white;
  background-color: #ff9800;
  font-family: "Lucida Grande", "Arial", sans-serif;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  padding: 5px 0;
  width: 180px;
  white-space: nowrap;
  border-radius: 3px;
  border: 2px solid #616161;
}

.hasEngineOnlabels {
  color: white;
  background-color: #009688;
  font-family: "Lucida Grande", "Arial", sans-serif;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  padding: 5px 0;
  width: 180px;
  white-space: nowrap;
  border-radius: 3px;
  border: 2px solid #616161;
}

.hasEngineOfflabels {
  color: white;
  background-color: #111111;
  font-family: "Lucida Grande", "Arial", sans-serif;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  padding: 5px 0;
  width: 180px;
  white-space: nowrap;
  border-radius: 3px;
  border: 2px solid #616161;
}
</style>
