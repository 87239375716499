<template>
  <v-main
    class="grey lighten-5"
    style="position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;"
  >
    <v-container fill-height>
      <v-layout align-center justify-center>
        <v-flex>
          <p class="text-xs-center display-4 font-weight-regular">403</p>
          <p class="text-xs-center title">Unauthorized Access</p>
          <p class="text-xs-center">You don't have permission to access this page.</p>
          <p class="text-xs-center">
            <a href="/">Back to Main Page</a>
          </p>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
export default {
  created() {
    this.$store.dispatch("clearStore");
  }
};
</script>

<style>
</style>
