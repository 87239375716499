import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("../Login.vue"),
  },
  {
    path: "/registration",
    name: "Registration",
    component: () => import("../Registration.vue"),
  },
  {
    path: "/home",
    name: "Home",
    // component: () => import("../views/Home.vue"),
  },
  {
    path: "/pages",
    name: "Pages",
    component: () => import("../views/Pages.vue"),
  },
  {
    path: "/roles",
    name: "Roles",
    component: () => import("../views/Roles.vue"),
  },
  {
    path: "/company-roles",
    name: "Company Roles",
    component: () => import("../views/CompanyRoles.vue"),
  },
  {
    path: "/sub-company-roles",
    name: "Sub Company Roles",
    component: () => import("../views/SubCompanyRoles.vue"),
  },
  {
    path: "/employees",
    name: "Employees",
    component: () => import("../views/Employees.vue"),
  },
  {
    path: "/pre-approve-clients",
    name: "Pre-Approve Clients",
    component: () => import("../views/PreApproveClients"),
  },
  {
    path: "/companies",
    name: "Companies",
    component: () => import("../views/Companies.vue"),
  },
  {
    path: "/gps-device",
    name: "GPS Device",
    component: () => import("../views/GPSDevices.vue"),
  },
  {
    path: "/gps-device-types",
    name: "GPS Device Types",
    component: () => import("../views/GPSDeviceTypes.vue"),
  },

  // Company Routes

  {
    path: "/c/home",
    name: "Home",
    component: () => import("../views/company/Dashboard.vue"),
  },
  {
    path: "/c/dashboard/map-view",
    name: "Dashboard Map View",
    component: () => import("../views/company/Home.vue"),
  },
  {
    path: "/c/pages",
    name: "Pages",
    component: () => import("../views/company/Pages.vue"),
  },
  {
    path: "/c/roles",
    name: "Roles",
    component: () => import("../views/company/Roles.vue"),
  },
  {
    path: "/c/employees",
    name: "Employees",
    component: () => import("../views/company/Employees.vue"),
  },
  {
    path: "/c/vehicles",
    name: "Vehicles",
    component: () => import("../views/company/Vehicles.vue"),
  },
  {
    path: "/c/outlet-monitoring",
    name: "Dispatch Monitoring",
    component: () => import("../views/company/OutletDispatch.vue"),
  },
  {
    path: "/c/active-monitoring",
    name: "Active Monitoring",
    component: () => import("../views/company/ActiveMonitoring.vue"),
  },
  {
    path: "/c/outlets",
    name: "Outlets",
    component: () => import("../views/company/Outlets.vue"),
  },
  {
    path: "/c/notifications",
    name: "Notifications",
    component: () => import("../views/company/Notifications.vue"),
  },
  {
    path: "/c/sub-companies",
    name: "Sub Companies",
    component: () => import("../views/company/SubCompanies.vue"),
  },
  {
    path: "/c/sub-company-roles",
    name: "Sub Company Roles",
    component: () => import("../views/company/SubCompanyRoles.vue"),
  },

  // Company - Safety Alerts

  {
    path: "/c/door-sensor",
    name: "Safety Alerts",
    component: () => import("../views/company/safety_alerts/DoorSensor.vue"),
  },
  {
    path: "/c/engine-on-off",
    name: "Safety Alerts",
    component: () => import("../views/company/safety_alerts/EngineOnOff.vue"),
  },
  {
    path: "/c/geozone-alert",
    name: "Safety Alerts",
    component: () => import("../views/company/safety_alerts/GeozoneAlert.vue"),
  },
  {
    path: "/c/harsh-break-alert",
    name: "Safety Alerts",
    component: () =>
      import("../views/company/safety_alerts/HarshBreakAlert.vue"),
  },
  {
    path: "/c/idle-alert",
    name: "Safety Alerts",
    component: () => import("../views/company/safety_alerts/IdleAlert.vue"),
  },
  {
    path: "/c/overspeeding",
    name: "Safety Alerts",
    component: () => import("../views/company/safety_alerts/Overspeeding.vue"),
  },
  {
    path: "/c/pit-stop-alert",
    name: "Safety Alerts",
    component: () => import("../views/company/safety_alerts/PitstopAlert.vue"),
  },
  {
    path: "/c/sudden-acceleration-alert",
    name: "Safety Alerts",
    component: () =>
      import("../views/company/safety_alerts/SuddenAcceleration.vue"),
  },
  {
    path: "/c/towing-alert",
    name: "Safety Alerts",
    component: () => import("../views/company/safety_alerts/TowingAlert.vue"),
  },
  {
    path: "/c/temperature-report",
    name: "Safety Alerts",
    component: () =>
      import("../views/company/safety_alerts/TemperatureReport.vue"),
  },

  // Company - Security Alerts

  {
    path: "/c/circuit-cut-off-alert",
    name: "Security Alerts",
    component: () =>
      import("../views/company/security_alerts/CircuitCutOffAlert.vue"),
  },
  {
    path: "/c/geofence-alert",
    name: "Security Alerts",
    component: () =>
      import("../views/company/security_alerts/GeofenceAlert.vue"),
  },
  {
    path: "/c/hotspot-alert",
    name: "Security Alerts",
    component: () =>
      import("../views/company/security_alerts/HotspotAlert.vue"),
  },
  {
    path: "/c/panic-button",
    name: "Security Alerts",
    component: () => import("../views/company/security_alerts/PanicButton.vue"),
  },

  // Company - Dispatch Report

  {
    path: "/c/detailed-report",
    name: "Dispatch Report",
    component: () =>
      import("../views/company/dispatch_reports/DetailedReport.vue"),
  },
  {
    path: "/c/dispatch-alarms",
    name: "Dispatch Report",
    component: () =>
      import("../views/company/dispatch_reports/DispatchAlarms.vue"),
  },

  // Company - Vehicle Usage

  {
    path: "/c/tracklist-report",
    name: "Vehicle Usage",
    component: () =>
      import("../views/company/vehicle_usage/TracklistReport.vue"),
  },
  {
    path: "/c/km-run-report",
    name: "Vehicle Usage",
    component: () => import("../views/company/vehicle_usage/KMRunReport.vue"),
  },
  {
    path: "/c/working-hours-report",
    name: "Vehicle Usage",
    component: () =>
      import("../views/company/vehicle_usage/WorkingHoursReport.vue"),
  },
  {
    path: "/c/smr-report",
    name: "Vehicle Usage",
    component: () => import("../views/company/vehicle_usage/SMRReport.vue"),
  },
  // {
  //   path: "/c/temperature-report",
  //   name: "Vehicle Usage",
  //   component: () =>
  //     import("../views/company/vehicle_usage/TemperatureReport.vue"),
  // },
  {
    path: "/c/fuel-sensor-report",
    name: "Vehicle Usage",
    component: () => import("../views/company/vehicle_usage/FuelSensor.vue"),
  },

  // Sub Company Routes

  {
    path: "/c/s/home",
    name: "Home",
    component: () => import("../views/subcompany/Dashboard.vue"),
  },
  {
    path: "/c/s/dashboard/map-view",
    name: "Dashboard Map View",
    component: () => import("../views/subcompany/Home.vue"),
  },
  {
    path: "/c/s/pages",
    name: "Pages",
    component: () => import("../views/subcompany/Pages.vue"),
  },
  {
    path: "/c/s/roles",
    name: "Roles",
    component: () => import("../views/subcompany/Roles.vue"),
  },
  {
    path: "/c/s/employees",
    name: "Employees",
    component: () => import("../views/subcompany/Employees.vue"),
  },
  {
    path: "/c/s/vehicles",
    name: "Vehicles",
    component: () => import("../views/subcompany/Vehicles.vue"),
  },
  {
    path: "/c/s/outlets",
    name: "Outlets",
    component: () => import("../views/subcompany/Outlets.vue"),
  },
  {
    path: "/c/s/outlet-monitoring",
    name: "Dispatch Monitoring",
    component: () => import("../views/subcompany/OutletDispatch.vue"),
  },
  {
    path: "/c/s/active-monitoring",
    name: "Active Monitoring",
    component: () => import("../views/subcompany/ActiveMonitoring.vue"),
  },

  // Sub Company - Safety Alerts

  {
    path: "/c/s/door-sensor",
    name: "Safety Alerts",
    component: () => import("../views/subcompany/safety_alerts/DoorSensor.vue"),
  },
  {
    path: "/c/s/engine-on-off",
    name: "Safety Alerts",
    component: () =>
      import("../views/subcompany/safety_alerts/EngineOnOff.vue"),
  },
  {
    path: "/c/s/geozone-alert",
    name: "Safety Alerts",
    component: () =>
      import("../views/subcompany/safety_alerts/GeozoneAlert.vue"),
  },
  {
    path: "/c/s/harsh-break-alert",
    name: "Safety Alerts",
    component: () =>
      import("../views/subcompany/safety_alerts/HarshBreakAlert.vue"),
  },
  {
    path: "/c/s/idle-alert",
    name: "Safety Alerts",
    component: () => import("../views/subcompany/safety_alerts/IdleAlert.vue"),
  },
  {
    path: "/c/s/overspeeding",
    name: "Safety Alerts",
    component: () =>
      import("../views/subcompany/safety_alerts/Overspeeding.vue"),
  },
  {
    path: "/c/s/pit-stop-alert",
    name: "Safety Alerts",
    component: () =>
      import("../views/subcompany/safety_alerts/PitstopAlert.vue"),
  },
  {
    path: "/c/s/sudden-acceleration-alert",
    name: "Safety Alerts",
    component: () =>
      import("../views/subcompany/safety_alerts/SuddenAcceleration.vue"),
  },
  {
    path: "/c/s/towing-alert",
    name: "Safety Alerts",
    component: () =>
      import("../views/subcompany/safety_alerts/TowingAlert.vue"),
  },

  // Sub Company - Security Alerts

  {
    path: "/c/s/circuit-cut-off-alert",
    name: "Security Alerts",
    component: () =>
      import("../views/subcompany/security_alerts/CircuitCutOffAlert.vue"),
  },
  {
    path: "/c/s/geofence-alert",
    name: "Security Alerts",
    component: () =>
      import("../views/subcompany/security_alerts/GeofenceAlert.vue"),
  },
  {
    path: "/c/s/hotspot-alert",
    name: "Security Alerts",
    component: () =>
      import("../views/subcompany/security_alerts/HotspotAlert.vue"),
  },
  {
    path: "/c/s/panic-button",
    name: "Security Alerts",
    component: () =>
      import("../views/subcompany/security_alerts/PanicButton.vue"),
  },

  // Sub Company - Dispatch Report

  {
    path: "/c/s/detailed-report",
    name: "Dispatch Report",
    component: () =>
      import("../views/subcompany/dispatch_reports/DetailedReport.vue"),
  },
  {
    path: "/c/s/dispatch-alarms",
    name: "Dispatch Report",
    component: () =>
      import("../views/subcompany/dispatch_reports/DispatchAlarms.vue"),
  },

  // Sub Company - Vehicle Usage

  {
    path: "/c/s/tracklist-report",
    name: "Vehicle Usage",
    component: () =>
      import("../views/subcompany/vehicle_usage/TracklistReport.vue"),
  },
  {
    path: "/c/s/km-run-report",
    name: "Vehicle Usage",
    component: () =>
      import("../views/subcompany/vehicle_usage/KMRunReport.vue"),
  },
  {
    path: "/c/s/working-hours-report",
    name: "Vehicle Usage",
    component: () =>
      import("../views/subcompany/vehicle_usage/WorkingHoursReport.vue"),
  },
  {
    path: "/c/s/smr-report",
    name: "Vehicle Usage",
    component: () => import("../views/subcompany/vehicle_usage/SMRReport.vue"),
  },
  {
    path: "/c/s/temperature-report",
    name: "Vehicle Usage",
    component: () =>
      import("../views/subcompany/vehicle_usage/TemperatureReport.vue"),
  },
  {
    path: "/c/s/fuel-sensor-report",
    name: "Vehicle Usage",
    component: () => import("../views/subcompany/vehicle_usage/FuelSensor.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
