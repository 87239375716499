<template>
  <nav style="height: 0;">
    <div @mouseout="mouseOutMini" @mouseover="mouseOverMini">
      <v-navigation-drawer
        class="grey lighten-4"
        v-model="drawer"
        width="270"
        fixed
        app
        clipped
        :mini-variant="miniVariant"
      >
        <v-list dense nav>
          <v-list-item-group color="primary">
            <template v-for="item in $store.state.menu">
              <v-list-item :key="item.name" link router :to="item.link">
                <v-list-item-icon>
                  <m-icon :icon="item.name"></m-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{
                    $capitalizeName(item.name)
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </div>

    <v-app-bar class="gradientColor" clipped-left app dark dense>
      <v-app-bar-nav-icon
        class="d-flex d-sm-none d-none d-sm-flex d-md-none"
        @click="drawer = true"
      ></v-app-bar-nav-icon>

      <a
        v-if="$store.state.employee.enable_mark_logo"
        class="mt-1"
        @click="$store.state.client_status ? backToCompany() : backToMain()"
      >
        <!-- <v-tooltip top>
          <template v-slot:activator="{ on, attrs }"> -->
        <img
          class="elevation-4 image-logo"
          :src="require('../../public/mark_title_bar.png')"
          height="35px"
        />
        <!-- <v-icon x-large v-bind="attrs" v-on="on">mdi-menu-left</v-icon> -->
        <!-- </template>
          <span>Back To Main</span>
        </v-tooltip> -->
      </a>

      <v-divider inset vertical></v-divider>

      <v-toolbar-title
        v-if="
          !$store.state.client_status &&
            $isEmpty($store.state.company) &&
            $isEmpty($store.state.subcompany)
        "
        class="ml-0 pl-3"
      >
        <span class="white--text">{{
          $route.name ? $route.name.toUpperCase() : ""
        }}</span>
      </v-toolbar-title>

      <v-toolbar-title
        @click="backToCompany"
        v-if="
          !$store.state.client_status &&
            !$isEmpty($store.state.company) &&
            $store.state.employee.user_designation !== 'SUB-COMPANY'
        "
        class="ml-3"
      >
        <a class="white--text">{{ $store.state.company.name.toUpperCase() }}</a>
      </v-toolbar-title>

      <v-toolbar-title
        v-if="
          !$store.state.client_status &&
            !$isEmpty($store.state.company) &&
            $store.state.employee.user_designation !== 'SUB-COMPANY'
        "
      >
        <v-icon slot="divider">mdi-menu-right</v-icon>
      </v-toolbar-title>

      <v-toolbar-title
        v-if="
          !$store.state.client_status &&
            !$isEmpty($store.state.company) &&
            $isEmpty($store.state.subcompany)
        "
        class="ml-0 pl-3"
      >
        <span class="white--text">{{
          $route.name ? $route.name.toUpperCase() : ""
        }}</span>
      </v-toolbar-title>

      <v-toolbar-title
        @click="backToSubCompany"
        v-if="
          !$store.state.client_status &&
            !$isEmpty($store.state.company) &&
            !$isEmpty($store.state.subcompany)
        "
      >
        <a class="white--text">{{
          $store.state.subcompany.name.toUpperCase()
        }}</a>
      </v-toolbar-title>

      <v-toolbar-title
        v-if="
          !$store.state.client_status &&
            !$isEmpty($store.state.company) &&
            !$isEmpty($store.state.subcompany)
        "
      >
        <v-icon slot="divider">mdi-menu-right</v-icon>
      </v-toolbar-title>

      <v-toolbar-title
        v-if="
          !$store.state.client_status &&
            !$isEmpty($store.state.company) &&
            !$isEmpty($store.state.subcompany)
        "
        class="ml-0 pl-3"
      >
        <span class="white--text">{{
          $route.name ? $route.name.toUpperCase() : ""
        }}</span>
      </v-toolbar-title>

      <v-toolbar-title
        v-if="
          !$store.state.client_status &&
            !$isEmpty($store.state.company) &&
            $store.state.reportTypeText !== ''
        "
      >
        <v-icon slot="divider">mdi-menu-right</v-icon>
      </v-toolbar-title>

      <v-toolbar-title
        class="ml-0 pl-3"
        v-if="$store.state.reportTypeText !== ''"
      >
        <span class="white--text">{{
          $store.state.reportTypeText.toUpperCase()
        }}</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu
        v-if="$store.state.company.id"
        offset-y
        transition="slide-y-transition"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            @click="getNotifications()"
            style="margin-right: 10px; background-color: #B71C1C;"
            small
            icon
            v-on="on"
          >
            <v-badge
              :value="$store.state.notificationCount"
              color="red"
              overlap
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on">{{
                    $store.state.notificationCount !== 0
                      ? "mdi-bell-ring"
                      : "mdi-bell"
                  }}</v-icon>
                </template>
                <span>Notifications</span>
              </v-tooltip>
            </v-badge>
          </v-btn>
        </template>
        <v-card class="elevation-0" style="width: 400px;">
          <v-subheader
            class="gradientColor white--text text-uppercase font-weight-bold"
            style="height: 35px; border-radius: 0px;"
            >Notifications</v-subheader
          >
          <div class="scrolling-wrapper">
            <v-container v-if="loadingNotifications">
              <v-row>
                <v-col cols="12">
                  <center>
                    <span class="primary--text">Loading...</span>
                  </center>
                  <v-progress-linear
                    indeterminate
                    color="primary"
                  ></v-progress-linear>
                </v-col>
              </v-row>
            </v-container>
            <v-card-text
              style="height: 100%;"
              v-else-if="notificationsItems.length === 0"
              class="text-center"
            >
              <v-container style="height: 100%;">
                <v-row style="height: 100%;">
                  <v-col
                    style="height: 100%; padding: 8% 0;"
                    md="8"
                    offset-md="2"
                  >
                    <span class="title font-weight-bold grey--text"
                      >NO DATA AVAILABLE.</span
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-text
              v-else
              v-for="(item, i) in notificationsItems"
              :key="i"
              class="notification pa-0"
            >
              <v-subheader
                :class="['black--text text-uppercase font-weight-bold']"
                style="height: 15px; border-radius: 0px; background: #dddddd; padding: 10px 5px;"
                >{{
                  item.alarm_type === 0 && item.alarm_status === 1
                    ? "ENGINE ON ALERT"
                    : item.alarm_type === 0 && item.alarm_status === 0
                    ? "ENGINE OFF ALERT"
                    : item.alarm_type === 1
                    ? "CIRCUIT CUT-OFF ALERT"
                    : item.alarm_type === 2
                    ? "PANIC BUTTON ALERT"
                    : item.alarm_type === 3
                    ? "OVERSPEEDING ALERT"
                    : item.alarm_type === 4
                    ? "IDLE ALERT"
                    : item.alarm_type === 5
                    ? "PITSTOP ALERT"
                    : item.alarm_type === 6
                    ? "GEOZONE ALERT"
                    : item.alarm_type === 7
                    ? "GEOFENCE ALERT"
                    : item.alarm_type === 8
                    ? "HOTSPOT ALERT"
                    : ""
                }}</v-subheader
              >
              <v-row no-gutters>
                <v-col cols="12" class="pa-3">
                  Company:
                  {{ $store.state.company.name.toUpperCase() }}
                  <br />Type:
                  <span class="black--text font-weight-bold">{{
                    item.alarm_type === 0 ||
                    item.alarm_type === 3 ||
                    item.alarm_type === 4 ||
                    item.alarm_type === 5 ||
                    item.alarm_type === 6 ||
                    item.alarm_type === 7 ||
                    item.alarm_type === 8
                      ? "SAFETY"
                      : item.alarm_type === 1 || item.alarm_type === 2
                      ? "SECURITY"
                      : ""
                  }}</span>
                  <br />
                  Time:
                  {{ item.datetimestamp }}
                  <br />
                  Plate No:
                  {{ item.plate_no }}
                  <br />
                  Location:
                  {{ item.location }}
                </v-col>
              </v-row>
            </v-card-text>
          </div>
          <v-card-actions style="border-top: 1px solid #dddddd;">
            <v-btn text class="blue--text" @click="seeAll()">
              <span style="text-decoration: underline;">See All</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-menu
        :close-on-content-click="false"
        offset-y
        transition="slide-y-transition"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            small
            icon
            v-on="on"
            style="margin-right: 5px; background-color: #B71C1C;"
          >
            <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-card class="mx-auto">
          <v-card-title class="pa-3">
            <span>
              <v-icon color="red">mdi-account-circle</v-icon>
              &nbsp;{{ $store.state.employee.name.toUpperCase() }}
            </span>
          </v-card-title>
          <v-divider></v-divider>
          <v-list>
            <v-list-item-group>
              <v-list-item @click="openUserSettingsDialog()">
                <v-list-item-title>
                  User Settings
                </v-list-item-title>
                <v-list-item-icon>
                  <v-icon>mdi-account-cog</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item @click="openChangePasswordDialog()">
                <v-list-item-title>Change Password</v-list-item-title>
                <v-list-item-icon>
                  <v-icon>mdi-lock-reset</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item @click="logout">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon color="red">mdi-logout-variant</v-icon>&nbsp; Logout
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <!---- Here is the User Settings Dialog ----->

    <full-width-dialog
      :dialog="userSettingsDialog"
      :dialogLoading="fullWidthDialogLoading"
      :dialogLoadingColor="'blue-grey darken-2'"
      :title="'User Settings'"
      :color="'blue-grey darken-2'"
      :saveButton="true"
      dark
      @saveDialog="openSaveUserSettingsDialog"
      @closeDialog="closeUserSettingsDialog"
    >
      <v-container fluid>
        <v-row>
          <v-col md="6">
            <v-list subheader three-line>
              <v-row>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                  <v-list-item>
                    <v-list-item-action>
                      <v-switch v-model="autoLogout" color="success"></v-switch>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>Auto Logout</v-list-item-title>
                      <v-list-item-subtitle>{{
                        autoLogout ? "ON" : "OFF"
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </full-width-dialog>

    <!---- Here is the User Settings Confirmation ---->

    <mini-dialog
      :dialog="confirmUserSettingsDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      @closeDialog="closeSaveUserSettingsDialog()"
      @confirmDialog="saveUserSettings()"
    ></mini-dialog>

    <!---- Here is the Change Password Dialog ----->

    <full-width-dialog
      :dialog="changePasswordDialog"
      :dialogLoading="fullWidthDialogLoading"
      :dialogLoadingColor="'blue-grey darken-2'"
      :title="'Change User Password'"
      :color="'blue-grey darken-2'"
      :saveButton="true"
      dark
      @saveDialog="validateForm"
      @closeDialog="closeChangePasswordDialog"
    >
      <v-container fluid>
        <v-row>
          <v-col md="6" offset-md="3">
            <v-card>
              <v-card-title style="background-color: #EEEEEE;"
                >Password Details</v-card-title
              >
              <v-divider></v-divider>
              <v-card-text>
                <change-password-details-container
                  :data="data"
                  :show1="show1"
                  :show2="show2"
                  :show3="show3"
                  ref="detailsContainer"
                  @openSaveDialog="openSaveChangePasswordDialog"
                ></change-password-details-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </full-width-dialog>

    <mini-dialog
      :dialog="saveChangePasswordDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      @closeDialog="closeSaveChangePasswordDialog"
      @confirmDialog="changePassword()"
    ></mini-dialog>

    <v-dialog
      v-model="passwordChangeDialog"
      scrollable
      max-width="500px"
      persistent
    >
      <v-card>
        <v-card-text class="pt-5">
          <v-alert type="info"
            >Please change your password as soon as possible for your own
            security.</v-alert
          >

          <br />
          <span class="font-weight-black">Procedure:</span> Click the
          <v-icon>mdi-menu-down</v-icon>&nbsp;button at the top right corner and
          click the Change Password
          <v-icon>mdi-lock-reset</v-icon>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="confirmPasswordChange()"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="$store.state.dataToLarge"
      scrollable
      max-width="500px"
      persistent
    >
      <v-card>
        <v-card-text class="pt-5">
          <v-alert type="error">Data Too Large!</v-alert>
          <span class="font-weight-black">Suggested Action:</span> Query data
          per day.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dataToLargeConfirm()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <audio id="notificationAudio">
      <source src="../assets/audio/notification.mp3" type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>

    <v-idle
      v-if="this.$store.state.employee.auto_logout"
      @idle="logout"
      :duration="60 * 30"
    />
  </nav>
</template>

<script>
import Services from "@/services/Services";
import CompanyServices from "@/services/CompanyServices";
import ChangePasswordDetailsContainer from "@/components/details_container/ChangePasswordDetailsContainer";

export default {
  components: {
    ChangePasswordDetailsContainer,
  },
  data: () => {
    return {
      drawer: true,
      miniVariant: true,
      loadingNotifications: false,
      notificationsItems: [],
      data: {},
      show1: false,
      show2: false,
      show3: false,
      changePasswordDialog: false,
      saveChangePasswordDialog: false,
      fullWidthDialogLoading: false,
      confirmLoading: false,
      passwordChangeDialog: true,
      notificationCount: 0,
      userSettingsDialog: false,
      confirmUserSettingsDialog: false,
      autoLogout: false,
    };
  },
  async created() {
    if (this.$store.state.employee.password_changed) {
      this.passwordChangeDialog = false;
    }

    // if (this.$store.state.company.id) {
    //   await this.$socket.emit("notification", this.$store.state.company.id);

    //   await this.sockets.subscribe("notification", (data) => {
    //     if (data.company_id === this.$store.state.company.id) {
    //       this.$store.dispatch("dispatchNotificationCount", data.count);

    //       this.notificationCount = data.count;
    //     }
    //   });
    // }

    this.autoLogout = this.$store.state.employee.auto_logout;
  },
  watch: {
    notificationCount: async function() {
      const notifAudio = document.getElementById("notificationAudio");
      notifAudio.play();
    },
  },
  methods: {
    confirmPasswordChange() {
      this.passwordChangeDialog = !this.passwordChangeDialog;
    },
    dataToLargeConfirm() {
      this.$store.dispatch("dispatchDataToLarge", false);
    },
    mouseOverMini() {
      this.miniVariant = false;
    },
    mouseOutMini() {
      this.miniVariant = true;
    },
    backToMain() {
      if (this.$store.state.main_menu.length) {
        let page = this.$isEmpty(this.$store.state.company)
          ? this.$store.state.main_menu[0]
          : this.$store.state.main_menu.find((item) => {
              return item.link === "/companies";
            });

        if (page) {
          if (this.$route.path !== page.link) {
            this.$store.dispatch("dispatchMenu", this.$store.state.main_menu);
            this.$router.push({
              path: page.link,
            });

            this.$store.dispatch("dispatchCompany", {});
            this.$store.dispatch("dispatchSubCompany", {});
          }
        }
      }
    },
    async backToCompany() {
      if (this.$store.state.company_menu.length) {
        let page = this.$isEmpty(this.$store.state.subcompany)
          ? this.$store.state.company_menu[0]
          : this.$store.state.company_menu.find((item) => {
              return item.link === "/c/sub-companies";
            });

        if (page) {
          if (this.$route.path !== page.link) {
            await this.$store.dispatch("dispatchSubCompany", {});

            await this.$store.dispatch(
              "dispatchMenu",
              this.$store.state.company_menu
            );

            await this.$router.push({
              path: page.link,
            });
          }
        }
      }
    },
    backToSubCompany() {
      if (this.$store.state.sub_company_menu.length) {
        let page = this.$store.state.sub_company_menu[0];
        if (page) {
          if (this.$route.path !== page.link) {
            this.$store.dispatch(
              "dispatchMenu",
              this.$store.state.sub_company_menu
            );
            this.$router.push({
              path: page.link,
            });
          }
        }
      }
    },
    async openChangePasswordDialog() {
      this.changePasswordDialog = true;
      this.fullWidthDialogLoading = true;
      this.data.current_password = "";
      this.data.new_password = "";
      this.data.confirm_password = "";
      this.show1 = false;
      this.show2 = false;
      this.show3 = false;

      setTimeout(() => {
        this.fullWidthDialogLoading = false;
      }, 600);
    },
    async closeChangePasswordDialog() {
      this.changePasswordDialog = false;
    },
    async openUserSettingsDialog() {
      this.userSettingsDialog = true;
      this.fullWidthDialogLoading = true;

      setTimeout(() => {
        this.fullWidthDialogLoading = false;
      }, 600);
    },
    async closeUserSettingsDialog() {
      this.userSettingsDialog = false;
    },
    openSaveUserSettingsDialog() {
      this.confirmUserSettingsDialog = true;
    },
    closeSaveUserSettingsDialog() {
      this.confirmUserSettingsDialog = false;
    },
    validateForm() {
      this.$refs.detailsContainer.validateForm();
    },
    openSaveChangePasswordDialog() {
      this.saveChangePasswordDialog = true;
    },
    closeSaveChangePasswordDialog() {
      this.saveChangePasswordDialog = false;
    },
    async changePassword() {
      this.confirmLoading = true;

      const data = this.$refs.detailsContainer.computedData;

      let body = {
        current_password: data.current_password,
        new_password: data.new_password,
        confirm_password: data.confirm_password,
      };

      try {
        const response = await Services.changePassword(
          body,
          this.$store.state.employee.user_id
        );

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeChangePasswordDialog();
          this.closeSaveChangePasswordDialog();
          this.$infoBar({
            type: "success",
            text: "Password has been changed!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }

      this.confirmLoading = await false;
    },
    async saveUserSettings() {
      this.confirmLoading = true;

      let body = {
        auto_logout: this.autoLogout,
      };

      try {
        const response = await Services.userSettings(
          body,
          this.$store.state.employee.user_id
        );

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          const returnedData = response.data;

          await this.$store.dispatch(
            "dispatchAutoLogout",
            returnedData.auto_logout
          );

          this.closeUserSettingsDialog();
          this.closeSaveUserSettingsDialog();
          this.$infoBar({
            type: "success",
            text: "User Settings Save!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }

      this.confirmLoading = await false;
    },
    async getNotifications() {
      this.loadingNotifications = true;

      await this.$store.dispatch("dispatchNotificationCount", 0);

      this.notificationsItems = (
        await CompanyServices.getNotifications({
          company_id: this.$store.state.company.id,
          date_time_from: this.$dateFormat(new Date(), "YYYY-MM-DD"),
          date_time_to: this.$dateFormat(new Date(), "YYYY-MM-DD"),
        })
      ).data.data;

      setTimeout(() => {
        this.loadingNotifications = false;
      }, 600);
    },
    seeAll() {
      this.$router.push({
        path: "/c/notifications",
      });
    },
    async logout() {
      await this.$router.push({
        path: "/",
      });

      document.title = `MARK - Mobile Assets Real-Time Keeper`;

      // await CompanyServices.disconnectSocketConnection();
      await this.$store.dispatch("clearStore");
    },
    titleCase(str) {
      let splitStr = str.toLowerCase().split(" ");
      for (let i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },
  },
};
</script>

<style scoped>
.gradientColor {
  background-color: #990000;
  background-image: linear-gradient(147deg, #990000 0%, #ff0000 74%);
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.notification:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}

.scrolling-wrapper {
  overflow-x: hidden;
  height: 170px;
  overflow-y: var(--overflow-y);
  padding: 0px;
}

.image-logo {
  border-radius: 50%;
  margin-right: 10px;
}

.image-logo:hover {
  outline: 1px solid rgb(255, 143, 143);
}

::v-deep ::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
</style>
