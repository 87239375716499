<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-btn fab small text v-on="on" @click="clickIcon()">
        <svg
          id="Layer_1"
          :width="xSmall ? 15 : width"
          :height="xSmall ? 15 : height"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 130 126.99"
        >
          <path
            d="M10,125.61V25L86.22,11.5v127L78,137.12q-24.38-4.2-48.75-8.41C22.87,127.62,16.43,126.64,10,125.61ZM28.65,50.55c.5,1.12.86,2,1.27,2.82,3.2,6.57,6.35,13.15,9.64,19.67A3.69,3.69,0,0,1,39.5,77c-3.57,6.48-7,13-10.5,19.56-.47.9-.84,1.85-1.36,3,3,.26,5.72.37,8.37.75,2.24.32,3.24-.63,4-2.61,2.13-5.4,4.45-10.73,6.9-16.6.55,1.19.88,1.84,1.16,2.51,2.12,5.15,4.2,10.31,6.38,15.43.38.88,1.14,2.17,1.83,2.25,3.77.41,7.58.5,11.78.72-.63-1.37-1-2.33-1.49-3.23C62.87,91.68,59.2,84.6,55.36,77.61a5.05,5.05,0,0,1-.07-5.5c3.78-6.92,7.36-13.94,11-20.93.45-.86.8-1.77,1.12-2.48-3.3,0-6.26,0-9.22,0-1.83,0-2.7.84-3.35,2.5-1.78,4.56-3.75,9-5.65,13.55-.49,1.16-1,2.29-1.75,3.87-2.31-6-4.32-11.36-6.54-16.67a3.64,3.64,0,0,0-2.51-2C35.26,49.9,32.12,50.29,28.65,50.55Z"
            transform="translate(-10 -11.5)"
            fill="#009444"
          />
          <path
            d="M107.06,64.73V51.59H94.58v-3.8H107V34.89H94.69V27.08c.63-.06,1.37-.19,2.1-.2,13.19,0,26.39.05,39.58-.05,2.77,0,3.64.86,3.63,3.67-.09,28.51-.06,57-.06,85.53a14.71,14.71,0,0,1-.11,2.3c-.77,4.7-1.06,4.93-5.86,4.93H94.59v-8.4H107V102.51H94.68V98h12.5c0-4,.1-7.64-.1-11.25,0-.54-1.39-1.39-2.17-1.43-3.35-.16-6.71-.07-10.27-.07V81.05h12.52c0-4,.08-7.77-.09-11.47,0-.45-1.36-1.16-2.1-1.18-3.36-.13-6.72-.06-10.3-.06V64.73Zm24.92-13H111.22V64.52H132Zm-20.84-4.27h20.75V34.91H111.14Zm-.15,21c0,3.61.08,7,0,10.29-.06,1.81.68,2.33,2.37,2.31,4.42-.07,8.84,0,13.26,0h5.3V68.47Zm21,17.14H111.21V97.76H132Zm0,29v-12H111.2v12Z"
            transform="translate(-10 -11.5)"
            fill="#009444"
          />
        </svg>
      </v-btn>
    </template>
    <span>{{ toolTip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    xSmall: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 20
    },
    width: {
      type: Number,
      default: 20
    },
    toolTip: {
      type: String,
      default: "Download Excel"
    }
  },
  methods: {
    clickIcon() {
      this.$emit("click");
    }
  }
};
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>