<template>
  <v-tooltip :top="top" :right="right" :bottom="bottom" :left="left">
    <template v-slot:activator="{ on }">
      <v-btn
        @click="atClick()"
        :style="styleButton"
        :dark="dark"
        fab
        :x-small="xsmall"
        :color="color"
        v-on="on"
      >
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ tooltipSpan }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    top: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    },
    bottom: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ""
    },
    tooltipSpan: {
      type: String,
      default: ""
    },
    styleButton: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: "primary"
    },
    dark: {
      type: Boolean,
      default: false
    },
    xsmall: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    atClick() {
      this.$emit("click");
    }
  }
};
</script>

<style>
</style>