<template>
  <v-snackbar
    v-model="infobarStatus"
    :color="$store.state.infobar.type === 'error' ? 'error darken-3' : $store.state.infobar.type === 'success' ? 'success' : ''"
    right
  >
    <v-avatar>
      <v-icon
        dark
      >{{ $store.state.infobar.type === "error" ? "mdi-alert-circle" : $store.state.infobar.type === "success" ? "mdi-check-circle" : "" }}</v-icon>
    </v-avatar>
    {{ $store.state.infobar.text }}
    <v-spacer></v-spacer>
  </v-snackbar>
</template>
<script>
export default {
  props: {},
  data() {
    return {};
  },
  computed: {
    infobarStatus: {
      get() {
        return this.$store.state.infobarStatus;
      },
      set(value) {
        this.$store.dispatch("dispatchInfobarStatus", value);
        this.$store.dispatch("dispatchInfobar", {
          type: "",
          text: ""
        });
      }
    }
  }
};
</script>