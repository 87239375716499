import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  state: {
    token: null,
    client_status: false,
    employee: {},
    initialMenu: [],
    menu: [],
    main_menu: [],
    company_menu: [],
    sub_company_menu: [],
    module: [],
    companies: [],
    infobarStatus: false,
    infobar: {
      type: "",
      text: "",
    },
    loading: false,
    unauthorized: false,
    company: {},
    company_settings: {},
    sub_company_settings: {},
    subcompany: {},
    isConnected: false,
    socketMessage: "",
    vehicle_array: [],
    backup_vehicle_array: [],
    dataToLarge: false,
    reportTypeText: "",
    reportTypeValue: "",
    reportVehiclesArray: [],
    showChevronButtons: false,
    hasItems: false,
    notificationCount: 0,
    snailtrail: [],
    dashboard_boxes_counts: {},
  },
  mutations: {
    SOCKET_CONNECT(state) {
      state.isConnected = true;
    },
    SOCKET_DISCONNECT(state) {
      state.isConnected = false;
    },
    SOCKET_MESSAGECHANNEL(state, message) {
      state.socketMessage = message;
    },
    setToken(state, token) {
      state.token = token;
    },
    setClientStatus(state, status) {
      state.client_status = status;
    },
    setEmployee(state, employee) {
      state.employee = employee;
    },
    setCompany(state, data) {
      state.company = data;
    },
    setSubCompany(state, data) {
      state.subcompany = data;
    },
    setCompanySettings(state, data) {
      state.company_settings = data;
    },
    setSubCompanySettings(state, data) {
      state.sub_company_settings = data;
    },
    setCompanies(state, companies) {
      state.companies = companies;
    },
    setMenu(state, menu) {
      state.menu = menu;
    },
    setMainMenu(state, menu) {
      state.main_menu = menu;
    },
    setCompanyMenu(state, menu) {
      state.company_menu = menu;
    },
    setSubCompanyMenu(state, menu) {
      state.sub_company_menu = menu;
    },
    setModule(state, menu) {
      state.module = menu;
    },
    setInfobar(state, info) {
      state.infobar = info;
    },
    setInfobarStatus(state, info) {
      state.infobarStatus = info;
    },
    setUnauthorized(state, status) {
      state.unauthorized = status;
    },
    setLoading(state, status) {
      state.loading = status;
    },
    setCurrentTransaction(state, status) {
      if (status.length !== 0) {
        state.vehicle_array = status;
      } else {
        state.vehicle_array.length = 0;
      }
    },
    setBackUpCurrentTransaction(state, status) {
      if (status.length !== 0) {
        state.backup_vehicle_array = status;
      } else {
        state.backup_vehicle_array.length = 0;
      }
    },
    setDataToLarge(state, status) {
      state.dataToLarge = status;
    },
    setReportTypeText(state, status) {
      state.reportTypeText = status;
    },
    setReportTypeValue(state, status) {
      state.reportTypeValue = status;
    },
    setReportVehiclesArray(state, status) {
      state.reportVehiclesArray = status;
    },
    setShowChevronButtons(state, status) {
      state.showChevronButtons = status;
    },
    setHasItems(state, status) {
      state.hasItems = status;
    },
    setNotificationCount(state, count) {
      state.notificationCount = count;
    },
    setSnailtrail(state, data) {
      state.snailtrail = data;
    },
    setAutoLogout(state, data) {
      state.employee.auto_logout = data;
    },
    setDashboardBoxesCounts(state, data) {
      state.dashboard_boxes_counts = data;
    },
  },
  actions: {
    dispatchToken({ commit }, token) {
      commit("setToken", token);
    },
    dispatchClientStatus({ commit }, status) {
      commit("setClientStatus", status);
    },
    dispatchEmployee({ commit }, object) {
      commit("setEmployee", object);
    },
    dispatchCompanies({ commit }, array) {
      commit("setCompanies", array);
    },
    dispatchCompany({ commit }, object) {
      commit("setCompany", object);
    },
    dispatchSubCompany({ commit }, object) {
      commit("setSubCompany", object);
    },
    dispatchCompanySettings({ commit }, object) {
      commit("setCompanySettings", object);
    },
    dispatchSubCompanySettings({ commit }, object) {
      commit("setSubCompanySettings", object);
    },
    dispatchMenu({ commit }, array) {
      commit("setMenu", array);
    },
    dispatchMainMenu({ commit }, array) {
      commit("setMainMenu", array);
    },
    dispatchCompanyMenu({ commit }, array) {
      commit("setCompanyMenu", array);
    },
    dispatchSubCompanyMenu({ commit }, array) {
      commit("setSubCompanyMenu", array);
    },
    dispatchModule({ commit }, array) {
      commit("setModule", array);
    },
    dispatchInfobar({ commit }, object) {
      commit("setInfobar", object);
    },
    dispatchInfobarStatus({ commit }, object) {
      commit("setInfobarStatus", object);
    },
    dispatchUnauthorized({ commit }, status) {
      commit("setUnauthorized", status);
    },
    dispatchCurrentTransaction({ commit }, status) {
      commit("setCurrentTransaction", status);
    },
    dispatchBackUpCurrentTransaction({ commit }, status) {
      commit("setBackUpCurrentTransaction", status);
    },
    dispatchDataToLarge({ commit }, status) {
      commit("setDataToLarge", status);
    },
    dispatchReportTypeText({ commit }, status) {
      commit("setReportTypeText", status);
    },
    dispatchReportTypeValue({ commit }, status) {
      commit("setReportTypeValue", status);
    },
    dispatchReportVehiclesArray({ commit }, status) {
      commit("setReportVehiclesArray", status);
    },
    dispatchShowChevronButtons({ commit }, status) {
      commit("setShowChevronButtons", status);
    },
    dispatchHasItems({ commit }, status) {
      commit("setHasItems", status);
    },
    dispatchNotificationCount({ commit }, count) {
      commit("setNotificationCount", count);
    },
    dispatchSnailtrail({ commit }, data) {
      commit("setSnailtrail", data);
    },
    dispatchAutoLogout({ commit }, status) {
      commit("setAutoLogout", status);
    },
    dispatchDashboardBoxesCounts({ commit }, obj) {
      commit("setDashboardBoxesCounts", obj);
    },
    startLoading({ commit }) {
      commit("setLoading", true);
    },
    endLoading({ commit }) {
      commit("setLoading", false);
    },
    clearStore({ commit }) {
      try {
        commit("setToken", null);
        commit("setClientStatus", false);
        commit("setEmployee", {});
        commit("setCompanies", []);
        commit("setMenu", []);
        commit("setMainMenu", []);
        commit("setCompanyMenu", []);
        commit("setSubCompanyMenu", []);
        commit("setLoading", false);
        commit("setCompany", {});
        commit("setCompanySettings", {});
        commit("setSubCompany", {});
        commit("setSubCompanySettings", {});
        commit("setDataToLarge", false);
        commit("setReportTypeText", "");
        commit("setReportTypeValue", "");
        commit("setReportVehiclesArray", []);
        commit("setShowChevronButtons", false);
        commit("setHasItems", false);
        commit("setNotificationCount", 0);
        commit("setSnailtrail", []);
        commit("setCurrentTransaction", []);
        commit("setBackUpCurrentTransaction", []);
        commit("setDashboardBoxesCounts", {});
      } catch (error) {
        console.log(error);
      }
    },
  },
  modules: {},
});
