import Rest from "@/services/api_config/RestApi";
import RealtimeDashboard from "@/services/api_config/RealtimeDashboardApi";

export default {
  getPages(params) {
    let data = ``;
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    return Rest().get("wayko/com/page?" + data.substring(1));
  },
  getPage(id) {
    return Rest().get("wayko/com/page/" + id);
  },
  addPage(data) {
    return Rest().put("wayko/com/page/add", data);
  },
  editPage(id, data) {
    return Rest().patch("wayko/com/page/" + id, data);
  },
  deletePage(id) {
    return Rest().delete("wayko/com/page/" + id);
  },
  getModules(params) {
    let data = ``;
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }
    if (
      params.page_id !== null &&
      params.page_id !== undefined &&
      params.page_id !== ""
    ) {
      data += `&page_id=${params.page_id}`;
    }

    return Rest().get("wayko/com/module?" + data.substring(1));
  },
  addModule(data) {
    return Rest().put("wayko/com/module/add", data);
  },
  editModule(data, id) {
    return Rest().patch("wayko/com/module/" + id, data);
  },
  deleteModule(id) {
    return Rest().delete("wayko/com/module/" + id);
  },
  getOutlets(params) {
    let data = ``;
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    if (
      params.company_id !== null &&
      params.company_id !== undefined &&
      params.company_id !== ""
    ) {
      data += `&company_id=${params.company_id}`;
    }
    if (
      params.type !== null &&
      params.type !== undefined &&
      params.type !== ""
    ) {
      data += `&type=${params.type}`;
    }
    return Rest().get("wayko/com/outlets?" + data.substring(1));
    // return Rest().get("outlets/wayko/com/outlets?" + data.substring(1));
  },
  addOutlet(data) {
    return Rest().put("wayko/com/outlet", data);
    // return Rest().put("outlets/wayko/com/outlet", data);
  },
  editOutlet(data, id) {
    return Rest().patch("wayko/com/outlet/" + id, data);
    // return Rest().patch("outlets/wayko/com/outlet/" + id, data);
  },
  deleteOutlet(id) {
    return Rest().delete("wayko/com/outlet/" + id);
    // return Rest().delete("outlets/wayko/com/outlet/" + id);
  },
  getEnrolledVehicles(params) {
    let data = ``;

    if (
      params.outlet_id !== null &&
      params.outlet_id !== undefined &&
      params.outlet_id !== ""
    ) {
      data += `&outlet_id=${params.outlet_id}`;
    }
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }

    return Rest().get("wayko/com/get-enrolled-vehicles?" + data.substring(1));
    // return Rest().get(
    //   "outlets/wayko/com/get-enrolled-vehicles?" + data.substring(1)
    // );
  },
  getEnrollVehicles(data) {
    // let data = ``;
    // if (
    //   params.company_id !== null &&
    //   params.company_id !== undefined &&
    //   params.company_id !== ""
    // ) {
    //   data += `&company_id=${params.company_id}`;
    // }

    return Rest().post("wayko/com/get-enroll-vehicles?", data);
    // return Rest().post("outlets/wayko/com/get-enroll-vehicles?", data);
  },
  enrollVehicles(id, data) {
    return Rest().patch("wayko/com/enroll-vehicles/" + id, data);
    // return Rest().patch("outlets/wayko/com/enroll-vehicles/" + id, data);
  },
  unassignVehicle(data) {
    // return Rest().post("/wayko/com/unassigned-vehicle", data);
    return Rest().post("outlets/wayko/com/unassigned-vehicle", data);
  },
  assignOutletSubCompany(id, data) {
    // return Rest().patch("wayko/com/outlet-assign-sub-company/" + id, data);
    return Rest().patch(
      "outlets/wayko/com/outlet-assign-sub-company/" + id,
      data
    );
  },
  getOutletsMonitoring(params) {
    let data = ``;
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    if (
      params.company_id !== null &&
      params.company_id !== undefined &&
      params.company_id !== ""
    ) {
      data += `&company_id=${params.company_id}`;
    }
    return Rest().get("wayko/com/outlets-monitoring?" + data.substring(1));
  },
  getVehicles(params) {
    let data = ``;
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    if (
      params.order !== null &&
      params.order !== undefined &&
      params.order !== ""
    ) {
      data += `&order=${params.order}`;
    }
    if (
      params.desc !== null &&
      params.desc !== undefined &&
      params.desc !== ""
    ) {
      data += `&desc=${params.desc}`;
    }
    if (
      params.company_id !== null &&
      params.company_id !== undefined &&
      params.company_id !== ""
    ) {
      data += `&company_id=${params.company_id}`;
    }
    return Rest().get("wayko/com/vehicle?" + data.substring(1));
  },
  downloadAllVehicle(params) {
    let data = ``;

    if (
      params.company_id !== null &&
      params.company_id !== undefined &&
      params.company_id !== ""
    ) {
      data += `&company_id=${params.company_id}`;
    }
    return Rest().get("wayko/com/download-all-vehicles?" + data.substring(1));
  },
  getVehicle(id) {
    return Rest().get("wayko/com/vehicle/" + id);
  },
  addVehicle(data) {
    return Rest().put("wayko/com/vehicle/add", data);
  },
  editVehicle(id, data) {
    return Rest().patch("wayko/com/vehicle/" + id, data);
  },
  deleteVehicle(id, data) {
    return Rest().delete("wayko/com/vehicle/" + id, data);
  },
  getVehicleStatus(id) {
    return Rest().get("wayko/com/vehicle-status/" + id);
  },
  updateStatus(id, data) {
    return Rest().patch("wayko/com/vehicle-update-status/" + id, data);
  },
  uploadImages(data) {
    return Rest().put("wayko/com/vehicle/upload-images", data);
  },
  getImages(params) {
    let data = ``;
    if (
      params.company_id !== null &&
      params.company_id !== undefined &&
      params.company_id !== ""
    ) {
      data += `&company_id=${params.company_id}`;
    }
    if (
      params.vehicle_id !== null &&
      params.vehicle_id !== undefined &&
      params.vehicle_id !== ""
    ) {
      data += `&vehicle_id=${params.vehicle_id}`;
    }

    return Rest().get("wayko/com/vehicle/get-images?" + data.substring(1));
  },
  assignUnassignSubCompany(id, data) {
    return Rest().patch(
      "wayko/com/vehicle/assign-unassign-sub-company/" + id,
      data
    );
  },
  getTrackingDispatchMonitoringDispatches(params) {
    let data = ``;
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    if (
      params.order !== null &&
      params.order !== undefined &&
      params.order !== ""
    ) {
      data += `&order=${params.order}`;
    }
    if (
      params.company_id !== null &&
      params.company_id !== undefined &&
      params.company_id !== ""
    ) {
      data += `&company_id=${params.company_id}`;
    }
    if (
      params.appointment_date !== null &&
      params.appointment_date !== undefined &&
      params.appointment_date !== ""
    ) {
      data += `&appointment_date=${params.appointment_date}`;
    }
    if (
      params.current_module !== null &&
      params.current_module !== undefined &&
      params.current_module !== ""
    ) {
      data += `&current_module=${params.current_module}`;
    }

    // return Rest().get(
    // "wayko/com/dispatch-monitoring/company-dispatches?" + data.substring(1)
    // );
    return Rest().get(
      "outlet-dispatch/wayko/com/dispatch-monitoring/company-dispatches?" +
        data.substring(1)
    );
  },
  saveDispatchMonitoringDispatch(data) {
    // return Rest().post("wayko/com/dispatch-monitoring/upload-dispatches", data);
    return Rest().post(
      "outlet-dispatch/save/wayko/com/dispatch-monitoring/upload-dispatches",
      data
    );
  },
  cancelDispatchMonitoringDispatch(id) {
    // return Rest().patch("wayko/com/dispatch-monitoring/cancel-dispatch/" + id);
    return Rest().patch(
      "outlet-dispatch/cancel/wayko/com/dispatch-monitoring/cancel-dispatch/" +
        id
    );
  },
  closeDispatchMonitoringDispatch(id) {
    // return Rest().patch("wayko/com/dispatch-monitoring/close-dispatch/" + id);
    return Rest().patch(
      "outlet-dispatch/close/wayko/com/dispatch-monitoring/close-dispatch/" + id
    );
  },
  getTrackingActiveMonitoringDispatches(params) {
    let data = ``;
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    if (
      params.order !== null &&
      params.order !== undefined &&
      params.order !== ""
    ) {
      data += `&order=${params.order}`;
    }
    if (
      params.company_id !== null &&
      params.company_id !== undefined &&
      params.company_id !== ""
    ) {
      data += `&company_id=${params.company_id}`;
    }
    if (
      params.appointment_date !== null &&
      params.appointment_date !== undefined &&
      params.appointment_date !== ""
    ) {
      data += `&appointment_date=${params.appointment_date}`;
    }
    if (
      params.current_module !== null &&
      params.current_module !== undefined &&
      params.current_module !== ""
    ) {
      data += `&current_module=${params.current_module}`;
    }

    return Rest().get(
      "wayko/com/active-monitoring/company-dispatches?" + data.substring(1)
    );
  },
  saveActiveMonitoringDispatch(data) {
    return Rest().post("wayko/com/active-monitoring/upload-dispatches", data);
  },
  cancelActiveMonitoringDispatch(id) {
    return Rest().patch("wayko/com/active-monitoring/cancel-dispatch/" + id);
  },
  closeActiveMonitoringDispatch(id) {
    return Rest().patch("wayko/com/active-monitoring/close-dispatch/" + id);
  },
  uploadEmployee(data) {
    return Rest().put("wayko/com/employee/upload", data);
  },
  getEmployees(params) {
    let data = ``;
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    if (
      params.company_id !== null &&
      params.company_id !== undefined &&
      params.company_id !== ""
    ) {
      data += `&company_id=${params.company_id}`;
    }
    return Rest().get("wayko/com/employee?" + data.substring(1));
  },
  getEmployee(id) {
    return Rest().get("wayko/com/employee/" + id);
  },
  addEmployee(data) {
    return Rest().put("wayko/com/employee/add", data);
  },
  editEmployee(id, data) {
    return Rest().patch("wayko/com/employee/" + id, data);
  },
  deleteEmployee(id) {
    return Rest().delete("wayko/com/employee/" + id);
  },
  resetPassword(id) {
    return Rest().patch("wayko/com/employee/reset-password/" + id);
  },
  getRoles(params) {
    let data = ``;
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    if (
      params.company_id !== null &&
      params.company_id !== undefined &&
      params.company_id !== ""
    ) {
      data += `&company_id=${params.company_id}`;
    }
    return Rest().get("wayko/com/role?" + data.substring(1));
  },
  getRole(id) {
    return Rest().get("wayko/com/role/" + id);
  },
  addRole(data) {
    return Rest().put("wayko/com/role/add", data);
  },
  editRole(data, id) {
    return Rest().patch("wayko/com/role/" + id, data);
  },
  deleteRole(id) {
    return Rest().delete("wayko/com/role/" + id);
  },
  getGPSDevices(params) {
    let data = ``;
    if (
      params.company_id !== null &&
      params.company_id !== undefined &&
      params.company_id !== ""
    ) {
      data += `&company_id=${params.company_id}`;
    }
    if (
      params.sub_company_id !== null &&
      params.sub_company_id !== undefined &&
      params.sub_company_id !== ""
    ) {
      data += `&sub_company_id=${params.sub_company_id}`;
    }
    if (
      params.dialog_type !== null &&
      params.dialog_type !== undefined &&
      params.dialog_type !== ""
    ) {
      data += `&dialog_type=${params.dialog_type}`;
    }

    return Rest().get("wayko/com/gps-devices?" + data.substring(1));
  },
  assignGPS(id, data) {
    return Rest().patch("wayko/com/assign-gps/" + id, data);
  },
  getSubCompanies(params) {
    let data = ``;
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    if (
      params.company_id !== null &&
      params.company_id !== undefined &&
      params.company_id !== ""
    ) {
      data += `&company_id=${params.company_id}`;
    }
    return Rest().get("wayko/com/sub-companies?" + data.substring(1));
  },
  getSubCompany(id) {
    return Rest().get("wayko/com/sub-company/" + id);
  },
  addSubCompany(data) {
    return Rest().put("wayko/com/sub-company/add", data);
  },
  editSubCompany(id, data) {
    return Rest().patch("wayko/com/sub-company/" + id, data);
  },
  deleteSubCompany(id) {
    return Rest().delete("wayko/com/sub-company/" + id);
  },
  getSubCompanyMenu(params) {
    let data = ``;
    if (
      params.sub_company_id !== null &&
      params.sub_company_id !== undefined &&
      params.sub_company_id !== ""
    ) {
      data += `&sub_company_id=${params.sub_company_id}`;
    }

    return Rest().get(
      "wayko/com/sub/page/get-sub-company-menu?" + data.substring(1)
    );
  },
  getSubCompanyRoles(params) {
    let data = ``;
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    if (
      params.company_id !== null &&
      params.company_id !== undefined &&
      params.company_id !== ""
    ) {
      data += `&company_id=${params.company_id}`;
    }
    return Rest().get("wayko/com/sub-company-role?" + data.substring(1));
  },
  getSubCompanyRole(id) {
    return Rest().get("wayko/com/sub-company-role/" + id);
  },
  addSubCompanyRole(data) {
    return Rest().put("wayko/com/sub-company-role/add", data);
  },
  editSubCompanyRole(data, id) {
    return Rest().patch("wayko/com/sub-company-role/" + id, data);
  },
  deleteSubCompanyRole(id) {
    return Rest().delete("wayko/com/sub-company-role/" + id);
  },
  getSubCompanyPages(params) {
    let data = ``;
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    return Rest().get("wayko/com/sub/page?" + data.substring(1));
  },
  getSubCompanyPage(id) {
    return Rest().get("wayko/com/sub/page/" + id);
  },
  addSubCompanyPage(data) {
    return Rest().put("wayko/com/sub/page/add", data);
  },
  editSubCompanyPage(id, data) {
    return Rest().patch("wayko/com/sub/page/" + id, data);
  },
  deleteSubCompanyPage(id) {
    return Rest().delete("wayko/com/sub/page/" + id);
  },
  getSubCompanyModules(params) {
    let data = ``;
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }
    if (
      params.page_id !== null &&
      params.page_id !== undefined &&
      params.page_id !== ""
    ) {
      data += `&page_id=${params.page_id}`;
    }

    return Rest().get("wayko/com/sub/module?" + data.substring(1));
  },
  addSubCompanyModule(data) {
    return Rest().put("wayko/com/sub/module/add", data);
  },
  editSubCompanyModule(data, id) {
    return Rest().patch("wayko/com/sub/module/" + id, data);
  },
  deleteSubCompanyModule(id) {
    return Rest().delete("wayko/com/sub/module/" + id);
  },
  getDashboard(params) {
    let data = ``;
    if (
      params.company_id !== null &&
      params.company_id !== undefined &&
      params.company_id !== ""
    ) {
      data += `&company_id=${params.company_id}`;
    }

    return RealtimeDashboard().get(
      "wayko/com/tracking-dashboard?" + data.substring(1)
    );
    // return Rest().get(
    //   "realtime-socket/wayko/com/tracking-dashboard?" + data.substring(1)
    // );
  },
  getDashboardDetails(params) {
    let data = ``;
    if (
      params.company_id !== null &&
      params.company_id !== undefined &&
      params.company_id !== ""
    ) {
      data += `&company_id=${params.company_id}`;
    }

    return Rest().get(
      "wayko/com/tracking-dashboard-details?" + data.substring(1)
    );
    // return Rest().get(
    //   "realtime-socket/wayko/com/tracking-dashboard-details?" +
    //     data.substring(1)
    // );
  },
  getDashboardApi(params) {
    let data = ``;
    if (
      params.company_id !== null &&
      params.company_id !== undefined &&
      params.company_id !== ""
    ) {
      data += `&company_id=${params.company_id}`;
    }

    return RealtimeDashboard().get(
      "dashboard/wayko/com/tracking-dashboard-api?" + data.substring(1)
    );
    // return Rest().get(
    //   "realtime-socket/wayko/com/tracking-dashboard-details?" +
    //     data.substring(1)
    // );
  },
  getDashboardMapApi(params) {
    let data = ``;
    if (
      params.company_id !== null &&
      params.company_id !== undefined &&
      params.company_id !== ""
    ) {
      data += `&company_id=${params.company_id}`;
    }

    return RealtimeDashboard().get(
      "map/wayko/com/tracking-dashboard-api?" + data.substring(1)
    );
    // return Rest().get(
    //   "realtime-socket/wayko/com/tracking-dashboard-details?" +
    //     data.substring(1)
    // );
  },
  getDashboardRunningVehicles(params) {
    let data = ``;
    if (
      params.company_id !== null &&
      params.company_id !== undefined &&
      params.company_id !== ""
    ) {
      data += `&company_id=${params.company_id}`;
    }

    // return Rest().get(
    //   "wayko/com/tracking-dashboard-running-vehicles?" + data.substring(1)
    // );
    return Rest().get(
      "realtime-socket/wayko/com/tracking-dashboard-running-vehicles?" +
        data.substring(1)
    );
  },
  getCurrentAlerts(params) {
    let data = ``;
    if (
      params.imei_no !== null &&
      params.imei_no !== undefined &&
      params.imei_no !== ""
    ) {
      data += `&imei_no=${params.imei_no}`;
    }
    if (
      params.company_id !== null &&
      params.company_id !== undefined &&
      params.company_id !== ""
    ) {
      data += `&company_id=${params.company_id}`;
    }
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }

    return Rest().get("wayko/com/current-alerts?" + data.substring(1));
  },
  disconnectSocketConnection() {
    return RealtimeDashboard().post(
      "wayko/com/tracking-dashboard-disconnect-socket"
    );
    // return Rest().post(
    //   "realtime-socket/wayko/com/tracking-dashboard-disconnect-socket"
    // );
  },
  getAlerts(params) {
    let data = ``;
    if (
      params.company_id !== null &&
      params.company_id !== undefined &&
      params.company_id !== ""
    ) {
      data += `&company_id=${params.company_id}`;
    }

    return Rest().get("wayko/com/alerts?" + data.substring(1));
  },
  getNotifications(params) {
    let data = ``;
    if (
      params.company_id !== null &&
      params.company_id !== undefined &&
      params.company_id !== ""
    ) {
      data += `&company_id=${params.company_id}`;
    }
    if (
      params.date_time_from !== null &&
      params.date_time_from !== undefined &&
      params.date_time_from !== ""
    ) {
      data += `&date_time_from=${params.date_time_from}`;
    }
    if (
      params.date_time_to !== null &&
      params.date_time_to !== undefined &&
      params.date_time_to !== ""
    ) {
      data += `&date_time_to=${params.date_time_to}`;
    }

    return Rest().get(
      "notifications/wayko/com/notifications?" + data.substring(1)
    );
    // return Rest().get("wayko/com/notifications?" + data.substring(1));
  },
  getSnailtrail(params) {
    let data = ``;
    if (
      params.vehicle_id !== null &&
      params.vehicle_id !== undefined &&
      params.vehicle_id !== ""
    ) {
      data += `&vehicle_id=${params.vehicle_id}`;
    }
    if (
      params.plate_no !== null &&
      params.plate_no !== undefined &&
      params.plate_no !== ""
    ) {
      data += `&plate_no=${params.plate_no}`;
    }
    if (
      params.date_time_from !== null &&
      params.date_time_from !== undefined &&
      params.date_time_from !== ""
    ) {
      data += `&date_time_from=${params.date_time_from}`;
    }
    if (
      params.date_time_to !== null &&
      params.date_time_to !== undefined &&
      params.date_time_to !== ""
    ) {
      data += `&date_time_to=${params.date_time_to}`;
    }

    // return Rest().get(
    //   "wayko/com/tracking-dashboard-snailtrail?" + data.substring(1)
    // );
    // return Rest().get(
    //   "realtime-socket/wayko/com/tracking-dashboard-snailtrail?" +
    //     data.substring(1)
    // );
    return RealtimeDashboard().get(
      "dashboard-snailtrail/wayko/com/tracking-dashboard-snailtrail?" +
        data.substring(1)
    );
  },
  getConfig(id) {
    return Rest().get("wayko/com/company-settings/" + id);
  },
  setConfig(data) {
    return Rest().post("wayko/com/company-settings", data);
  },

  // Safety Alerts

  getEngineOnOffReport(data) {
    // return Rest().post("wayko/com/engine-on-off-report", data);
    return Rest().post("engine-on-off/wayko/com/engine-on-off-report", data);
  },
  getOverspeedingReport(data) {
    // return Rest().post("wayko/com/overspeeding-report", data);
    return Rest().post("overspeeding/wayko/com/overspeeding-report", data);
  },
  getIdleReport(data) {
    // return Rest().post("wayko/com/idle-report", data);
    return Rest().post("idle/wayko/com/idle-report", data);
  },
  getPitstopReport(data) {
    return Rest().post("wayko/com/pitstop-report", data);
  },
  getGeozoneReport(data) {
    return Rest().post("geozone/wayko/com/geozone-report", data);
    // return Rest().post("wayko/com/geozone-report", data);
  },
  getGeozoneSnailtrail(params) {
    let data = ``;
    if (
      params.plate_no !== null &&
      params.plate_no !== undefined &&
      params.plate_no !== ""
    ) {
      data += `&plate_no=${params.plate_no}`;
    }
    if (
      params.date_time_from !== null &&
      params.date_time_from !== undefined &&
      params.date_time_from !== ""
    ) {
      data += `&date_time_from=${params.date_time_from}`;
    }
    if (
      params.date_time_to !== null &&
      params.date_time_to !== undefined &&
      params.date_time_to !== ""
    ) {
      data += `&date_time_to=${params.date_time_to}`;
    }

    // return Rest().get(
    //   "wayko/com/geozone-report-snailtrail?" + data.substring(1)
    // );
    return Rest().get(
      "geozone/wayko/com/geozone-report-snailtrail?" + data.substring(1)
    );
  },

  // Security Alerts

  getCircuitCutOff(data) {
    return Rest().post("wayko/com/circuit-cut-off-report", data);
  },
  getPanicButton(data) {
    return Rest().post("wayko/com/panic-button-report", data);
  },
  getHotspotReport(data) {
    return Rest().post("wayko/com/hotspot-report", data);
  },
  getGeofenceReport(data) {
    return Rest().post("wayko/com/geofence-report", data);
  },

  // Vehicle Usage

  getKMRunReport(data) {
    // return Rest().post("wayko/com/km-run-report", data);
    return Rest().post("km-run/wayko/com/km-run-report", data);
  },
  getKMRunSnailtrail(params) {
    let data = ``;
    if (
      params.vehicle_id !== null &&
      params.vehicle_id !== undefined &&
      params.vehicle_id !== ""
    ) {
      data += `&vehicle_id=${params.vehicle_id}`;
    }
    if (
      params.plate_no !== null &&
      params.plate_no !== undefined &&
      params.plate_no !== ""
    ) {
      data += `&plate_no=${params.plate_no}`;
    }
    if (
      params.date_time_from !== null &&
      params.date_time_from !== undefined &&
      params.date_time_from !== ""
    ) {
      data += `&date_time_from=${params.date_time_from}`;
    }
    if (
      params.date_time_to !== null &&
      params.date_time_to !== undefined &&
      params.date_time_to !== ""
    ) {
      data += `&date_time_to=${params.date_time_to}`;
    }

    // return Rest().get(
    //   "wayko/com/km-run-report-snailtrail?" + data.substring(1)
    // );
    return Rest().get(
      "km-run-snailtrail/wayko/com/km-run-report-snailtrail?" +
        data.substring(1)
    );
  },
  getTracklistDatesReport(data) {
    // return Rest().post("wayko/com/tracklist-dates-report", data);
    return Rest().post("tracklist/wayko/com/tracklist-dates-report", data);
  },
  getTracklistReport(data) {
    // return Rest().post("wayko/com/tracklist-report", data);
    return Rest().post("tracklist/wayko/com/tracklist-report", data);
  },
  getTrackingSnailtrail(params) {
    let data = ``;
    // if (
    //   params.plate_no !== null &&
    //   params.plate_no !== undefined &&
    //   params.plate_no !== ""
    // ) {
    //   data += `&plate_no=${params.plate_no}`;
    // }
    if (
      params.vehicle_id !== null &&
      params.vehicle_id !== undefined &&
      params.vehicle_id !== ""
    ) {
      data += `&vehicle_id=${params.vehicle_id}`;
    }
    if (
      params.date_time_from !== null &&
      params.date_time_from !== undefined &&
      params.date_time_from !== ""
    ) {
      data += `&date_time_from=${params.date_time_from}`;
    }
    if (
      params.date_time_to !== null &&
      params.date_time_to !== undefined &&
      params.date_time_to !== ""
    ) {
      data += `&date_time_to=${params.date_time_to}`;
    }

    // return Rest().get(
    //   "wayko/com/tracklist-report-snailtrail?" + data.substring(1)
    // );

    return Rest().get(
      "tracklist-snailtrail/wayko/com/tracklist-report-snailtrail?" +
        data.substring(1)
    );
  },
  getTracklistDownloadData(params) {
    let data = ``;
    if (
      params.vehicle_id !== null &&
      params.vehicle_id !== undefined &&
      params.vehicle_id !== ""
    ) {
      data += `&vehicle_id=${params.vehicle_id}`;
    }
    if (
      params.date_time_from !== null &&
      params.date_time_from !== undefined &&
      params.date_time_from !== ""
    ) {
      data += `&date_time_from=${params.date_time_from}`;
    }
    if (
      params.date_time_to !== null &&
      params.date_time_to !== undefined &&
      params.date_time_to !== ""
    ) {
      data += `&date_time_to=${params.date_time_to}`;
    }

    // return Rest().get("wayko/com/tracklist-download-data?" + data.substring(1));
    return Rest().get(
      "tracklist/wayko/com/tracklist-download-data?" + data.substring(1)
    );
  },
  getWorkingHoursReport(data) {
    // return Rest().post("wayko/com/working-hours-report", data);
    return Rest().post("working-hours/wayko/com/working-hours-report", data);
  },
  getWorkingHoursSnailtrail(params) {
    let data = ``;
    if (
      params.vehicle_id !== null &&
      params.vehicle_id !== undefined &&
      params.vehicle_id !== ""
    ) {
      data += `&vehicle_id=${params.vehicle_id}`;
    }
    if (
      params.plate_no !== null &&
      params.plate_no !== undefined &&
      params.plate_no !== ""
    ) {
      data += `&plate_no=${params.plate_no}`;
    }
    if (
      params.date_time_from !== null &&
      params.date_time_from !== undefined &&
      params.date_time_from !== ""
    ) {
      data += `&date_time_from=${params.date_time_from}`;
    }
    if (
      params.date_time_to !== null &&
      params.date_time_to !== undefined &&
      params.date_time_to !== ""
    ) {
      data += `&date_time_to=${params.date_time_to}`;
    }

    // return Rest().get(
    //   "wayko/com/working-hours-report-snailtrail?" + data.substring(1)
    // );
    return Rest().get(
      "working-hours-snailtrail/wayko/com/working-hours-report-snailtrail?" +
        data.substring(1)
    );
  },
  getSMRReport(data) {
    return Rest().post("wayko/com/smr-report", data);
  },
  getTemperatureReport(data) {
    return Rest().post("wayko/com/temperature-report", data);
  },

  // Dispatch Reports

  getDetailedReport(data) {
    return Rest().post("wayko/com/detailed-report", data);
  },
};
