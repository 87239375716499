<template>
  <v-form ref="formChangePassword">
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="data.current_password"
          label="Current Password"
          :type="show1 ? 'text' : 'password'"
          required
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required]"
          @click:append="show1 = !show1"
        ></v-text-field>
        <v-text-field
          v-model="new_password"
          label="New Password"
          :type="show2 ? 'text' : 'password'"
          required
          persistent-hint
          :hint="new_password.length ? strength : ''"
          :color="passwordClasses"
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required]"
          @click:append="show2 = !show2"
        ></v-text-field>
        <v-progress-linear
          v-if="new_password.length > 0"
          v-model="progressLinearVModel"
          :buffer-value="buffer"
          :color="passwordClasses"
          class="mb-5"
        ></v-progress-linear>
        <v-text-field
          v-model="data.confirm_password"
          label="Confirm New Password"
          :type="show3 ? 'text' : 'password'"
          required
          :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, passwordConfirmationRule]"
          @click:append="show3 = !show3"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    show1: {
      type: Boolean,
      default: false
    },
    show2: {
      type: Boolean,
      default: false
    },
    show3: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      rules: {
        required: value => !!value || "This field is required."
      },
      new_password: "",
      buffer: 20
    };
  },
  computed: {
    computedData() {
      return {
        current_password: this.data.current_password,
        new_password: this.new_password,
        confirm_password: this.data.confirm_password
      };
    },
    passwordConfirmationRule() {
      return () =>
        this.new_password === this.data.confirm_password ||
        "New and Confirm New Password doesn't must match";
    },
    strength() {
      const length = this.new_password.length;
      const strength = {
        Strong: length >= 14,
        Medium: length < 14 && length >= 8,
        Weak: length < 8
      };
      return Object.keys(strength).find(key => strength[key]);
    },
    passwordClasses() {
      if (this.strength === "Weak") {
        return "red";
      } else if (this.strength === "Medium") {
        return "orange";
      } else if (this.strength === "Strong") {
        return "green";
      } else {
        return "";
      }
    },
    progressLinearVModel() {
      return this.new_password.length <= this.buffer
        ? this.new_password.length
        : this.buffer;
    }
  },
  methods: {
    resetForm() {
      this.$refs.formChangePassword.reset();
    },
    validateForm() {
      if (this.$refs.formChangePassword.validate()) {
        this.$emit("openSaveDialog");
      }
    }
  }
};
</script>

<style scoped>
</style>