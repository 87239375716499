import Vue from "vue";
import Vidle from "v-idle";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueWindowModal from "vue-window-modal";

import Mixins from "@/mixins/Mixins";

import OfficialMap from "@/components/OfficialMap";
import SearchField from "@/components/SearchField";
import InfoBar from "@/components/InfoBar";
import MiniDialog from "@/components/dialogs/MiniDialog";
import FullWidthDialog from "@/components/dialogs/FullWidthDialog";
import IconBase from "@/components/IconBase";
import FabButton from "@/components/buttons/FabButton";
import CancelDispatchButton from "@/components/buttons/CancelDispatchButton";
import CloseDispatchButton from "@/components/buttons/CloseDispatchButton";
import SnailtrailButton from "@/components/buttons/SnailtrailButton";
import ExcelButton from "@/components/buttons/ExcelButton";

// import VueSocketIO from "vue-socket.io";

// Vue.use(
//   new VueSocketIO({
//     connection: process.env.VUE_APP_SOCKET_IO_URL,
//     vuex: {
//       store,
//       actionPrefix: "SOCKET_",
//       mutationPrefix: "SOCKET_",
//     },
//   })
// );

Vue.use(Vidle);

Vue.mixin(Mixins);

Vue.component("official-map", OfficialMap);
Vue.component("search-field", SearchField);
Vue.component("info-bar", InfoBar);
Vue.component("mini-dialog", MiniDialog);
Vue.component("full-width-dialog", FullWidthDialog);
Vue.component("m-icon", IconBase);
Vue.component("fab-button", FabButton);
Vue.component("cancel-dispatch-button", CancelDispatchButton);
Vue.component("close-dispatch-button", CloseDispatchButton);
Vue.component("snailtrail-button", SnailtrailButton);
Vue.component("excel-button", ExcelButton);

Vue.use(VueWindowModal);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
