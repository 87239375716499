<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-btn icon :x-small="xSmall" v-on="on" @click="clickIcon()">
        <svg
          id="Layer_1"
          :width="xSmall ? 15 : 20"
          :height="xSmall ? 15 : 20"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 130 121.66"
        >
          <path
            d="M43.09,98C28.41,85.33,15.2,72.38,10.77,53.3,7.7,40.11,14.05,26.36,25.61,19.15a33.23,33.23,0,0,1,37.76,1.91C74.08,29.31,78.89,43.74,74.72,56.6,69.12,73.81,57,86.19,43.09,98ZM62.26,47.05a19.09,19.09,0,0,0-19-19.2A19.37,19.37,0,0,0,43,66.58,19.09,19.09,0,0,0,62.26,47.05Z"
            transform="translate(-10 -14.17)"
            fill="#f59422"
          />
          <path
            d="M42.76,110.53a2.34,2.34,0,0,1-2.18-2.4,35.08,35.08,0,0,1,.25-5.42,2.25,2.25,0,0,1,2.47-2.11,2.46,2.46,0,0,1,1.85,2.82,28.58,28.58,0,0,0-.21,4.53,2.38,2.38,0,0,1-2.1,2.57Z"
            transform="translate(-10 -14.17)"
            fill="#194c9e"
          />
          <path
            d="M64.87,135.83a20.84,20.84,0,0,1-8.41-1.74,2.6,2.6,0,0,1-1.26-3.21A2.11,2.11,0,0,1,58,129.44a17.05,17.05,0,0,0,6.86,1.41h0c.35,0,.7,0,1,0a2.31,2.31,0,0,1,2.28,2.37,2.39,2.39,0,0,1-2.07,2.61c-.41,0-.82,0-1.24,0Zm9.84-2.24a2.21,2.21,0,0,1-2-1.57,2.62,2.62,0,0,1,1.22-3.24A19.89,19.89,0,0,0,77,127.09a21,21,0,0,0,3.61-3.28,2,2,0,0,1,3.09,0,2.75,2.75,0,0,1,0,3.52,25.66,25.66,0,0,1-4.39,4,23.88,23.88,0,0,1-3.79,2.1A1.9,1.9,0,0,1,74.71,133.59Zm-24.95-4.81a2,2,0,0,1-1.52-.71,29,29,0,0,1-5.51-9.14,2.64,2.64,0,0,1,1.13-3.28,2.09,2.09,0,0,1,2.87,1.29,24.13,24.13,0,0,0,4.56,7.57,2.75,2.75,0,0,1,0,3.52A2.07,2.07,0,0,1,49.76,128.78Zm37.49-9a2,2,0,0,1-1-.25,2.66,2.66,0,0,1-1-3.33c.37-.86.65-1.6.87-2.18a22.79,22.79,0,0,0,1.56-6.66,2.26,2.26,0,0,1,2.39-2.22,2.43,2.43,0,0,1,2,2.73,28,28,0,0,1-1.89,8.1c-.24.64-.56,1.47-1,2.41A2.16,2.16,0,0,1,87.25,119.73ZM91.35,100a1.91,1.91,0,0,1-.62-.1,2.56,2.56,0,0,1-1.48-3.09,62.94,62.94,0,0,1,3.29-10,2.06,2.06,0,0,1,2.93-1.1,2.68,2.68,0,0,1,1,3.35,58.27,58.27,0,0,0-3,9.12A2.24,2.24,0,0,1,91.35,100Zm8.43-17.7a2.05,2.05,0,0,1-1.62-.82A2.75,2.75,0,0,1,98.32,78a21.69,21.69,0,0,1,4.71-3.7,20.44,20.44,0,0,1,3.91-1.74,2.17,2.17,0,0,1,2.7,1.71,2.56,2.56,0,0,1-1.5,3.08A16,16,0,0,0,105,78.73a17.16,17.16,0,0,0-3.75,3A2.05,2.05,0,0,1,99.78,82.32Zm25-2.3a2,2,0,0,1-1.13-.36l-.42-.28a17.91,17.91,0,0,0-7.13-2.7,2.45,2.45,0,0,1-1.88-2.79,2.25,2.25,0,0,1,2.45-2.14,21.83,21.83,0,0,1,8.71,3.3l.53.35a2.7,2.7,0,0,1,.74,3.42A2.14,2.14,0,0,1,124.79,80Z"
            transform="translate(-10 -14.17)"
            fill="#194c9e"
          />
          <path
            d="M133.48,91.29a2.19,2.19,0,0,1-2-1.46,25,25,0,0,0-2-3.9,2.71,2.71,0,0,1,.52-3.47,2,2,0,0,1,3,.59,29.76,29.76,0,0,1,2.37,4.72,2.64,2.64,0,0,1-1.08,3.3A2,2,0,0,1,133.48,91.29Z"
            transform="translate(-10 -14.17)"
            fill="#194c9e"
          />
          <path
            d="M112.37,108a27.6,27.6,0,0,1-20.73,5.76,26.73,26.73,0,0,1-18-9.9,27.75,27.75,0,1,1,43.3-.48l2.47,2.46a5.18,5.18,0,0,1,4.93.21,7.17,7.17,0,0,1,1.33,1.07q6.27,6.24,12.5,12.5a5.17,5.17,0,0,1,0,7.82c-.67.68-1.34,1.36-2,2a5.13,5.13,0,0,1-7.56,0q-6.43-6.37-12.81-12.8a5.23,5.23,0,0,1-1.24-5.82.79.79,0,0,0-.09-.66C113.82,109.42,113.1,108.71,112.37,108ZM72.47,86.17A22.63,22.63,0,1,0,95.12,63.58,22.63,22.63,0,0,0,72.47,86.17Z"
            transform="translate(-10 -14.17)"
            fill="#6e6f72"
          />
        </svg>
      </v-btn>
    </template>
    <span>View Snail Trail</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    xSmall: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clickIcon() {
      this.$emit("click");
    }
  }
};
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>