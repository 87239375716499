<template>
  <div width="100%" height="100%" style="position:fixed">
    <img
      width="100%"
      height="100%"
      style="position:fixed;"
      :src="require('../assets/white_bg.png')"
    />
    <v-main style="position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;">
      <v-container fill-height>
        <v-layout align-center justify-center>
          <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>
export default {};
</script>

<style>
.v-progress-circular {
  margin: 1rem;
}
</style>
