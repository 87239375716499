<template>
  <v-dialog
    v-model="dialog"
    scrollable
    :max-width="maxWidth"
    @keydown.esc="closeDialog()"
    persistent
  >
    <v-card>
      <v-card-title
        v-if="confirmSave"
        :style="
          dialogType == 'success'
            ? 'font-size: 30px; text-align: center; padding: 50px 0px 30px 120px'
            : 'text-align: left;'
        "
        >{{ cardTitle }}</v-card-title
      >
      <v-card-text
        v-if="confirmSave"
        :style="
          dialogType == 'success'
            ? 'text-align: center; font-size: 20px'
            : 'text-align: left;'
        "
        :class="cardText === '' ? 'pa-0' : ''"
      >
        {{ cardText }}

        <br v-if="checkLogo" /><br v-if="checkLogo" />
        <v-fab-transition>
          <v-icon v-if="checkLogo" color="green" x-large>
            mdi-check
          </v-icon>
        </v-fab-transition>
      </v-card-text>

      <v-container v-if="dialogLoading" style="text-align: center;">
        <v-row>
          <v-col cols="12">
            <v-progress-circular
              :size="50"
              :color="dialogLoadingColor"
              indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
      <slot v-else></slot>
      <v-card-actions v-if="cardActions">
        <v-spacer></v-spacer>
        <v-btn
          color="red darken-1"
          text
          @click="closeDialog"
          :loading="loading"
        >
          <h3 v-if="dialogType == 'success'">Ok</h3>
          <v-icon v-else>mdi-close</v-icon>
        </v-btn>
        <v-btn
          v-if="dialogType != 'success'"
          color="green darken-1"
          text
          @click="confirmDialog"
          :loading="loading"
        >
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    checkLogo: {
      type: Boolean,
      default: false,
    },
    dialogType: {
      type: String,
      default: "",
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      default: "500px",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    cardTitle: {
      type: String,
      default: "Save",
    },
    cardText: {
      type: String,
      default: "Are you sure you want to save this?",
    },
    cardActions: {
      type: Boolean,
      default: true,
    },
    confirmSave: {
      type: Boolean,
      default: false,
    },
    dialogLoading: {
      type: Boolean,
      default: false,
    },
    dialogLoadingColor: {
      type: String,
      default: "primary",
    },
  },
  methods: {
    closeDialog() {
      if (this.dialogType == "success") {
        this.$emit("goToLogin");
      }
      this.$emit("closeDialog");
    },
    confirmDialog() {
      this.$emit("confirmDialog");
    },
  },
};
</script>

<style></style>
